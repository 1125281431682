<template>
  <div class="about_company">
    <div class="about_company-block bg-gray">
      <div class="about_company-block_content bg-white">
        <div class="about_company-block_content_left">
          <h1 class="about_title">
            Alcotec - лидер по оптовой продаже бытовой техники в Украине!
          </h1>
        </div>
        <div class="about_company-block_content_right">
          <img :src="`${baseDomainImg}/public/images/static-page/about/about-company-01.png`" />
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="four-square">
          <p class="red-text font-weight-bold">
            1997
          </p>
          <p class="">
            Когда мы появились
          </p>
        </div>
        <div class="four-square">
          <p class="red-text font-weight-bold">
            > 40
          </p>
          <p class="">
            Широкий ассортимент торговых марок
          </p>
        </div>
        <div class="four-square">
          <p class="red-text font-weight-bold">
            1000+
          </p>
          <p class="">
            Успешные партнерства
          </p>
        </div>
        <div class="four-square">
          <p class="red-text font-weight-bold">
            > 20
          </p>
          <p class="">
            Многочисленные награды
          </p>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-gray">
      <div class="about_company-block_content bg-gray">
        <div class="about_company-block_content_left">
          <img class="mobile-off" :src="`${baseDomainImg}/public/images/static-page/about/EFYMOV.png`" />
        </div>
        <div class="about_company-block_content_right">
          <h2 class="about_title">
            Мы - <span class="red-text">украино-швейцарская компания</span> с многолетним опытом работы.
          </h2>
          <img class="desktop-off" :src="`${baseDomainImg}/public/images/static-page/about/EFYMOV.png`" />
          <p>
            В конце 90х, мы начинали с небольшого, нишевого рынка кухонных моек.
            Мы сделали упор на профессиональное консультирование.
            С тех пор нам удалось расширить ассортимент и предложить украинскому покупателю
            <span class="font-weight-bold">европейскую бытовую технику</span> и сопутствующие
            <span class="font-weight-bold">услуги экстра-класса.</span>
          </p>
          <p class="fio">
            <span>Александр Николаевич Ефимов</span>
            <br class="desktop-off" />
            <span>основатель и руководитель</span>
          </p>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="title">
          <p class="text_title">
            Из года в год Alcotec* подтверждает свою репутацию лидера отрасли:
          </p>
          <p class="small_text">
            *В группу компаний Alcotec входят ООО “Алтек”, ООО “Модуль-Стиль”, ООО “Нольте Партнер”
          </p>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="about_slider" @click="popUpImg($event)">
          <slick ref="slickCertificate" :options="slickOptionsCertificate">
            <div v-for="(slide, index) in certificateItems" :key="index">
              <div class="about_slider-item">
                <img :src="`${baseDomainImg}${slide}`" alt />
              </div>
            </div>
          </slick>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="title">
          <p class="text_title">
            В нашем ассортименте представлена бытовая техника более 40 известнейших <span class="red-text">торговых марок</span>.
          </p>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="about_slider">
          <slick ref="slickLogo" :options="slickOptionsLogo">
            <div v-for="(slide, index) in logoItems" :key="index">
              <a class="about_slider-item" :href="`${slide.url}`">
                <img :src="`${baseDomainImg}${slide.img}`" alt />
              </a>
            </div>
          </slick>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="about_slider sert">
          <a href="/catalog" class="about_slider-item">
            <img src="@/assets/img/about_company/ot.svg" />
            <p>Крупная отдельностоящая</p>
          </a>
          <a href="/catalog" class="about_slider-item">
            <img src="@/assets/img/about_company/vt.svg" />
            <p>Встраиваемая</p>
          </a>
          <a href="/catalog" class="about_slider-item">
            <img src="@/assets/img/about_company/smallbt.svg" />
            <p>Мелкая</p>
          </a>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-gray">
      <div class="about_company-block_content bg-gray">
        <div class="about_company-block_content_left">
          <p class="text_title">
            Alcotec имеет эксклюзивные права на ряд европейских брендов VIP-сегмента, включая ASKO, Gaggenau и AEG.
          </p>
          <p>
            Мы продаем только новую технику, обеспеченную гарантией производителя (гарантийным
            и постгарантийным обслуживанием в авторизованных сервисных центрах Украины).
          </p>
        </div>
        <div class="about_company-block_content_right">
          <img :src="`${baseDomainImg}/public/images/static-page/about/logo-extra.png`" />
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="about_company-block_content_left">
          <p class="text_title">
            Наша цель
          </p>
          <p>
            Надежное и долгосрочное сотрудничество с оптовыми партнерами, дизайнерами, архитекторами, салонами-магазинами, интернет-магазинами.
          </p>
        </div>
        <div class="about_company-block_content_right">
          <p class="text_title mobile-off" style="color: #FFFFFF;">
            _
          </p>
          <p>
            Мы гарантируем <span class="font-weight-bold">ценовую стабильность</span>
            и <span class="font-weight-bold">гибкую систему скидок для постоянных клиентов!</span>
          </p>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <p class="text_title">
          Наши услуги
        </p>
        <div class="table">
          <div class="table-column">
            <div class="table-row">
              <div class="icon">
                <img src="@/assets/img/about_company/consultation.png" />
              </div>
              <div class="text">
                <p>Консультирование</p>
              </div>
            </div>
            <div class="table-row">
              <div class="icon">
                <img src="@/assets/img/about_company/marketing.png" />
              </div>
              <div class="text">
                <p>Маркетинг</p>
              </div>
            </div>
            <div class="table-row">
              <div class="icon">
                <img src="@/assets/img/about_company/service.png" />
              </div>
              <div class="text">
                <p>Сервис</p>
              </div>
            </div>
            <div class="table-row">
              <div class="icon">
                <img src="@/assets/img/about_company/design.png" />
              </div>
              <div class="text">
                <p>Дизайн</p>
              </div>
            </div>
          </div>
          <div class="table-column">
            <div class="table-row">
              <div class="icon big">
                <img src="@/assets/img/about_company/complex.png" />
              </div>
              <div class="text big">
                <p>
                  Комплексные решения для комплектации кухни*<br />
                  <span>*в том числе для кухонной мебели Nolte (Германия) и Scavolini (Италия)</span>
                </p>
              </div>
            </div>
            <div class="table-row">
              <div class="icon">
                <img src="@/assets/img/about_company/save.png" />
              </div>
              <div class="text big">
                <p class="red-text">
                  Надежное хранение техники и логистика
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-white">
      <div class="about_company-block_content bg-white">
        <div class="about_company-block_content_left">
          <p>
            Мы следим за качеством наших услуг. Сотрудники Alcotec постоянно повышают свою
            квалификацию на обучающих семинарах в Украине и за рубежом.
          </p>
        </div>
        <div class="about_company-block_content_right">
          <img :src="`${baseDomainImg}/public/images/static-page/about/quality-1.png`" />
          <img :src="`${baseDomainImg}/public/images/static-page/about/quality-2.png`" />
        </div>
      </div>
    </div>
    <div class="about_company-block bg-gray">
      <div class="about_company-block_content bg-gray">
        <div class="about_company-block_content_left">
          <p class="text_title">
            Alcotec ищет людей!
          </p>
          <router-link to="/career" tag="button" class="btn">
            Перейти
          </router-link>
        </div>
        <div class="about_company-block_content_right">
          <p class="text_title">
            Мы верим, что каждый должен вносить свой
            вклад в развитие общества.
          </p>
          <ul>
            <li>
              <p>
                Для поддержки украинского строительного бизнеса Alcotec вступила в
                <a href="/page/uba" class="red-text">Украинскую строительную ассоциацию</a>.
              </p>
            </li>
            <li>
              <p>
                Для архитекторов и дизайнеров мы разработали и продвигаем проект
                <a href="/registration">
                  <span class="red-text">“Клуб архитекторов и дизайнеров Alcotec”</span>.
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-gray">
      <div class="about_company-block_content bg-gray">
        <div class="about_slider">
          <div class="about_slider-item">
            <img :src="`${baseDomainImg}/public/images/static-page/about/zamena.png`" />
            <p>Мы поменяем вашу старую бытовую технику на новую в рамках проекта <a href="/page/ZaMeNa" class="red-text">ZaMeNa</a>!</p>
          </div>
          <div class="about_slider-item">
            <img :src="`${baseDomainImg}/public/images/static-page/about/children.png`" />
            <p>Поддержим детские дома!</p>
          </div>
          <div class="about_slider-item">
            <img :src="`${baseDomainImg}/public/images/static-page/about/church.png`" />
            <p>Построим <a href="/page/church" class="red-text">церковь вместе</a>!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="about_company-block bg-gray">
      <div class="about_company-block_content bg-gray">
        <div class="about_company-block_content_left">
          <p class="text_title">
            Мы работаем в 14 крупных городах Украины:
          </p>
        </div>
        <div class="about_company-block_content_right">
        </div>
      </div>
    </div>
    <div class="about_company-block bg-gray">
      <GoogleMapLoader
        :map-config="mapConfig"
        :api-key="yourApiKey"
      >
        <template slot-scope="{ google, map }">
          <GoogleMapMarker
            v-for="marker in markers"
            :key="marker.id"
            :marker="marker"
            :google="google"
            :map="map"
          />
        </template>
      </GoogleMapLoader>
    </div>
    <div class="about_company-block bg-gray">
      <div class="about_company-block_content bg-gray">
        <div class="about_company-block_content_left">
          <p class="text_title">
            Контакты
          </p>
          <p class="tel">
            +38 (044) 500-26-26
          </p>
          <p><span class="font-weight-bold">e-mail:</span> info@alcotec.com.ua</p>
          <p><span class="font-weight-bold">Мы работаем:</span> Пн-Пт 9:00-18:00</p>
          <router-link to="/contacts" tag="button" class="btn">
            Контакты региональных офисов
          </router-link>
        </div>
        <div class="about_company-block_content_right">
          <p class="text_title mobile-off" style="color: #e5e5e5;">
            .
          </p>
          <p class="font-weight-bold">
            Центральный офис:
          </p>
          <p>04119, Украина, г. Киев</p>
          <p>ул. Дегтярёвская 25а, Корпус 1, секция №3</p>
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" width="auto">
      <v-card>
        <div class="popup-image" @click="modal = false">
          <img :src="`${clicked}`" alt />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Slick from 'vue-slick';
import { staticText } from '../i18n';
import { baseDomainImg } from '../constans';
import GoogleMapLoader from '../components/Map/GoogleMapLoader.vue';
import GoogleMapMarker from '../components/Map/GoogleMapMarker.vue';
import { mapSettings, markers } from '../components/Map/mapSettings';

export default {
  name: 'AboutCompany',
  components: {
    Slick,
    GoogleMapLoader,
    GoogleMapMarker,
  },
  data() {
    return {
      modal: false,
      clicked: '',
      yourApiKey: 'AIzaSyCWlM6SaDJMovhMfg6B8BcqgGhwKy-Y6po',
      staticText,
      baseDomainImg,
      markers,
      certificateItems: [],
      logoItems: [],
      slickOptionsCertificate: {
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      slickOptionsLogo: {
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 6,
        slidesToScroll: 1,
        dots: false,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
      mapCenter: { lat: 48.962395, lng: 32.227191 },
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      isLoggedIn: (state) => state.users.userData.isLoggedIn,
    }),
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      };
    },
  },
  created() {
    const sliderFn = this.sliderReslick;

    this.$store.dispatch('pages/getStaticPageInfo', {
      alias: 'about',
      cb(response) {
        sliderFn(response);
      },
      error() {

      },
    });
  },
  methods: {
    popUpImg(e) {
      const src = e.target.getAttribute('src');

      if (src !== null && src !== undefined) {
        this.modal = true;
        this.clicked = src;
      }
    },
    sliderReslick(response) {
      this.$refs.slickCertificate.destroy();
      this.$refs.slickLogo.destroy();
      this.certificateItems = response.images;
      this.logoItems = response.logos;
      this.$nextTick(() => {
        this.$refs.slickCertificate.create();
        this.$refs.slickLogo.create();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../node_modules/slick-carousel/slick/slick.scss';

::v-deep .v-dialog{
  width: auto;
}

.slick-prev{
  width: 40px;
  height: 40px;
  background-image: url("../assets/img/about_company/prev.svg");
}
.slick-next{
  width: 40px;
  height: 40px;
  background-image: url("../assets/img/about_company/next.svg");
}
.desktop-off{
  display: none;
}
.popup-image{
  background: transparent;
  display: flex;
  justify-content: center;
  & img {
    height: 80vh;
    width: auto;
    align-self: center;
  }
}
.about_company {
  margin-bottom: 0;
  padding-bottom: 0;
  background-color: #ffffff;
  min-height: 32.5vh;
}
.about_title {
  font-family: 'MagistralC';
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  font-weight: bold;
  padding-bottom: 32px;
  padding-top: 32px;
}
.text_title {
  font-family: 'MagistralC';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  font-weight: bold;
  padding-bottom: 24px;
  padding-top: 24px;
}
.small_text{
  font-size: 10px;
  line-height: 14px;
  color: #1d1d1d;
  font-weight: 300;
  padding-bottom: 0;
}
p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #1d1d1d;
  padding-bottom: 32px;
}
button.btn {
  background-color: $main;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: 'MagistralC';
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 18px 33px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;

  &:hover {
    background-color: #262626;
  }
}
ul {
  list-style: initial;
  margin-left: 18px;
}
.about_company{
  background-color: #e5e5e5;
  width: 100%;
}
.about_company-block {
  width: 100%;
  & ::v-deep .slick-prev{
    color: transparent;
    width: 40px;
    height: 40px;
    background-image: url("../assets/img/about_company/prev.svg");
    left: calc(50% - 60px);
  }
  & ::v-deep .slick-next{
    color: transparent;
    width: 40px;
    height: 40px;
    background-image: url("../assets/img/about_company/next.svg");
    right: calc(50% - 60px);
  }
  & ::v-deep .slick-arrow {
    transform: unset;
    bottom: -50px;
    top: unset;
  }
  &_content {
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    &_left{
      width: 50%;
      max-width: 50%;
    }
    &_right{
      width: 50%;
      max-width: 50%;
    }

  }
  &:nth-child(1){
    margin-top: 35px;
    margin-bottom: 80px;
    img{
      height: 100%;
    }
    .about_title{
      padding-left: 67px;
      padding-right: 67px;
      padding-top: 116px;
    }
  }
  &:nth-child(2){
    & div{
      justify-content: space-between;
      flex-wrap: wrap;
      height: 304px;
      align-items: center;
      & .four-square{
        width: 20%;
        height: 160px;
        & p{
          padding-right: 30px;
          padding-left: 30px;
          &:nth-child(1){
            font-family: 'MagistralC', sans-serif;
            font-size: 36px;
            border-bottom: 1px solid #DCC488;
            padding-bottom: 25px;
          }
          &:nth-child(2){
            font-size: 18px;
            margin-top: 30px;
          }
        }
      }
    }
  }
  &:nth-child(3){
    margin-top: 80px;
    margin-bottom: 80px;
    &  div:first-child{
      display: flex;
    }
    &  div:last-child{
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
    }
    img{
      height: auto;
    }
    .about_title{
      font-size: 24px;
      padding-top: 0;
    }
    .fio{
      padding-bottom: 0;
      span{
        padding-right: 10px;
        &:last-child{
          border-left: 1px solid #9B9B9B;
          color: #9B9B9B;
          font-weight: 400;
          padding-right: 0;
          padding-left: 10px;
        }
      }
    }
  }
  &:nth-child(4){
    .title{
      width: calc(100% / 12 * 5);
      padding-top: 77px;
      padding-bottom: 60px;
    }
  }
  &:nth-child(5){
    .about_slider{
      display: block;
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
      height: 190px;
      & .about_slider-item {
        border: 1px solid #C4C4C4;
        position: relative;
        height: 180px;
        padding: 0 10px;
        cursor: pointer;
        & .zoom-icon{
          position: absolute;
          top: 5px;
          right: 5px;
        }
        & img{
          height: 90%;
          width: auto;
          margin: 4% auto;
        }
      }
    }
  }
  &:nth-child(6){
    .title{
      width: calc(100% / 12 * 5);
      padding-top: 77px;
      padding-bottom: 60px;
    }
  }
  &:nth-child(7){
    .about_slider{
      display: block;
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
      & .about_slider-item {
        border: 1px solid #C4C4C4;
        position: relative;
        justify-content: center;
        & .zoom-icon{
          position: absolute;
          top: 5px;
          right: 5px;
        }
        & img{
          height: auto;
        }
      }
    }
  }
  &:nth-child(8){
    .about_slider{
      margin-top: 140px;
      margin-bottom: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      & .about_slider-item {
        width: calc(100% / 3);
        height: 281px;
        border: 1px solid #C4C4C4;
        position: relative;
        display: flex;
        justify-content: center;
        & img{
          height: max-content;
          align-self: center;
          width: 50%;
        }
        & p {
          display: inline-block;
          width: 50%;
          align-self: center;
        }
        &:hover{
          border: 1px solid $main;
          & p {
            color: $main;
          }
        }
      }

    }
  }
  &:nth-child(9){
    margin-top: 80px;
    margin-bottom: 80px;
    & .about_company-block_content_left{
      & p {
        padding-right: 55px;
      }
    }
    & .about_company-block_content_right{
      display: flex;
      & img {
        align-self: center;
        height: max-content;
      }
    }
  }
  &:nth-child(10){
    margin-top: 80px;
    padding-top: 80px;
    p {
      padding-right: 55px;
    }
  }
  &:nth-child(11){
    & .about_company-block_content{
      display: block;
    }
    padding-top: 80px;
    p {
      padding-right: 55px;
      padding-left: 55px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 700;
      font-family: 'MagistralC', sans-serif;
      font-size: 18px;
    }
    .text_title {
      font-family: 'MagistralC';
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.02em;
      color: #1d1d1d;
      font-weight: bold;
      padding-top: 24px;
      padding-left: 0;
    }
    .table{
      display: flex;
      width: 100%;
      box-sizing: border-box;
      border-left: 1px solid #C4C4C4;
      border-right: 1px solid #C4C4C4;
      margin-top: 45px;
      & .table-column{
        width: 50%;
        & .table-row{
          display: flex;
          border-bottom: 1px solid #C4C4C4;
          & .icon{
            width: 83px;
            height: 83px;
            background-color: $main;
            display: flex;
            justify-content: center;
            & img{
              align-self: center;
            }
            &.big{
              width: 83px;
              height: calc(83px * 3 + 3px);
              background-color: $main;
            }
          }
          & .text{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            &.big{
              width: calc(100% - 83px);
              & span{
                font-size: 14px;
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                padding-top: 15px;
              }
            }
            & p{
              align-self: center;
              width: 100%;
            }
          }
          &:first-child{
            border-top: 1px solid #C4C4C4;
          }
        }
      }
    }
  }
  &:nth-child(12){
    padding-top: 80px;
    padding-bottom: 80px;
    & .about_company-block_content_left{
      & p {
        padding-right: 55px;
      }
    }
    & .about_company-block_content_right{
      display: flex;
      justify-content: space-between;
      & img {
        align-self: center;
        height: max-content;
      }
    }
  }
  &:nth-child(13){
    padding-top: 80px;
    p {
      padding-right: 55px;
    }
  }
  &:nth-child(14){
    .about_slider{
      margin-top: 80px;
      margin-bottom: 50px;
      display: flex;
      flex-wrap: wrap;
      justify-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      & .about_slider-item {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        height: 352px;
        width: 360px;
        & img{
          height: max-content;
        }
        & p {
          padding: 10px 10px 0 0;
          font-weight: 700;
        }
      }

    }
  }
  &:nth-child(15){
    padding-bottom: 50px;
  }
  &:nth-child(16){
    width: 100%;
    height: 478px;
    & ::v-deep .google-map-wrap{
      width: 100%;
      height: 478px;
      & .gm-style-iw-d{
        & div{
          max-width: 250px;
          & p{
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 10px;
            line-height: 18px;
          }
        }
      }
    }
  }
  &:nth-child(17){
    padding-top: 80px;
    margin-bottom: 80px;
    p {
      padding-right: 55px;
      padding-bottom: 15px;
      line-height: 1.3;
    }
    .tel{
      font-weight: 400;
      font-size: 28px;
    }
    button.btn{
      margin-top: 30px;
      background-color: transparent;
      border: 1px solid $main;
      color: $main;
      font-size: 13px;
      font-weight: 700;
      padding: 14px 24px;
      &:hover {
        background-color: $main;
        color: $white;
      }
    }
  }
}
.bg-gray{
  background-color: #e5e5e5;
}
.bg-white{
  background-color: #FFFFFF;
}
.red-text{
  color: $main;
}
@media (max-width: 1200px) {
  .desktop-off{
    display: none;
  }
  p {
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 18px;
  }
  .text_title {
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 22px;
    padding-top: 22px;
  }
  .about_title{
    font-size: 22px;
    line-height: 28px;
  }
  .about_company-block {
    width: 100%;
    &_content {
      margin: 0 auto;
      max-width: calc(100% - 30px);
      display: flex;
      &_left{
        width: 50%;
        max-width: 50%;
      }
      &_right{
        width: 50%;
        max-width: 50%;
      }

    }
    &:nth-child(3){
      img{
        height: max-content;
      }
      .about_title{
        padding-left: 15px;
      }
      p {
        padding-left: 15px;
      }
    }
    &:nth-child(14){
      .about_slider{
        & .about_slider-item {
          height: 304px;
          width: 310px;
          & img{
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  ::v-deep .v-dialog{
    width: auto;
    height: auto;
  }
  .popup-image{
    & img {
      width: auto;
      height: auto;
    }
  }
  .desktop-off{
    display: block;
  }
  .mobile-off{
    display: none;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 14px;
  }
  .text_title {
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 22px;
    padding-top: 22px;
  }
  .about_title{
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 22px;
    padding-top: 22px;
  }
  .about_company-block {
    width: 100%;
    &_content {
      margin: 0 auto;
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
      display: flex;
      flex-direction: column;
      &_left{
        width: 100%;
        max-width: 100%;
      }
      &_right{
        width: 100%;
        max-width: 100%;
      }

    }
    &:nth-child(1){
      margin-top: 40px;
      margin-bottom: 40px;
      img {
        width: 100%;
        height: auto;
        display: inline-block;
        vertical-align: bottom;
      }
      .about_title{
        text-align: center;
        padding: 20px;
      }
    }
    &:nth-child(2){
      & div{
        display: block;
        height: auto;
        align-items: center;
        padding-top: 15px;
        & .four-square{
          width: 50%;
          height: 160px;
          display: inline-block;
          padding-left: 15px;
          padding-right: 15px;
          text-align: center;
          vertical-align: top;
          & p{
            padding-right: 0;
            padding-left: 0;
            &:nth-child(1){
              font-family: 'MagistralC', sans-serif;
              font-size: 26px;
              border-bottom: 1px solid #C4C4C4;
              padding-bottom: 15px;
              padding-top: 15px;
            }
            &:nth-child(2){
              font-size: 15px;
              margin-top: 20px;
            }
          }
        }
      }
    }
    &:nth-child(3){
      margin-top: 40px;
      margin-bottom: 40px;
      &  div:first-child{
        display: flex;
      }
      &  div:last-child{
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
      }
      img{
        height: auto;
      }
      .about_title{
        font-size: 24px;
        padding-top: 0;
        padding-left: 0;
      }
      .fio{
        padding-bottom: 15px;
        padding-top: 15px;
        order: 1;
        span{
          padding-right: 0;
          &:last-child{
            border-left: none;
            color: #9B9B9B;
            font-weight: 400;
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
      p{
        order: 2;
        padding-left: 0;
      }
    }
    &:nth-child(4){
      .title{
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    &:nth-child(5){
      .about_slider{
        display: block;
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        height: 190px;
        & .about_slider-item {
          border: 1px solid #C4C4C4;
          position: relative;
          height: 180px;
          padding: 0 10px;
          & .zoom-icon{
            position: absolute;
            top: 5px;
            right: 5px;
          }
          & img{
            height: 90%;
            width: auto;
            margin: 4% auto;
          }
        }
      }
    }
    &:nth-child(6){
      .title{
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    &:nth-child(8){
      .about_slider{
        margin-top: 80px;
        margin-bottom: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        & .about_slider-item {
          width: 100%;
          height: 100px;
          border: 1px solid #C4C4C4;
          position: relative;
          display: flex;
          justify-content: center;
          & .zoom-icon{
            position: absolute;
            top: 5px;
            right: 5px;
          }
          & img{
            height: 90%;
            align-self: center;
          }
        }

      }
    }
    &:nth-child(9){
      margin-top: 40px;
      margin-bottom: 40px;
      & .about_company-block_content_left{
        & p {
          padding-right: 0;
        }
      }
      & .about_company-block_content_right{
        display: flex;
        & img {
          align-self: center;
          height: max-content;
        }
      }
    }
    &:nth-child(10){
      margin-top: 40px;
      padding-top: 40px;
      p {
        padding-right: 0;
      }
    }
    &:nth-child(11){
      & .about_company-block_content{
        display: block;
      }
      padding-top: 20px;
      p {
        padding-right: 0;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 700;
        font-family: 'MagistralC', sans-serif;
        font-size: 18px;
      }
      .text_title {
        font-family: 'MagistralC';
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #1d1d1d;
        font-weight: bold;
        padding-top: 24px;
        padding-left: 0;
      }
      .table{
        display: flex;
        width: 100%;
        flex-direction: column;
        box-sizing: border-box;
        border-left: 1px solid #C4C4C4;
        border-right: 1px solid #C4C4C4;
        margin-top: 15px;
        & .table-column{
          width: 100%;
          & .table-row{
            display: flex;
            border-bottom: 1px solid #C4C4C4;
            & .icon{
              width: 83px;
              height: 83px;
              background-color: $main;
              display: flex;
              justify-content: center;
              & img{
                align-self: center;
              }
              &.big{
                width: 83px;
                height: calc(83px * 2 + 3px);
                background-color: $main;
              }
            }
            & .text{
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              &.big{
                width: calc(100% - 83px);
                & span{
                  font-size: 14px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 400;
                  padding-top: 15px;
                }
              }
              & p{
                align-self: center;
                width: 100%;
              }
            }
            &:first-child{
              border-top: 1px solid #C4C4C4;
            }
          }
          &:last-child{
            & .table-row{
              &:first-child{
                border-top: none;
              }
            }
          }
        }
      }
    }
    &:nth-child(12){
      padding-top: 40px;
      padding-bottom: 40px;
      & .about_company-block_content_left{
        & p {
          padding-right: 0;
          font-size: 16px;
        }
      }
      & .about_company-block_content_right{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        & img {
          align-self: center;
          height: auto;
          margin-bottom: 15px;
        }
      }
    }
    &:nth-child(13){
      padding-top: 40px;
      p {
        padding-right: 0;
      }
    }
    &:nth-child(14){
      .about_slider{
        margin-top: 40px;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        flex-direction: column;
        width: 100%;
        & .about_slider-item {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          height: unset;
          width: auto;
          margin-bottom: 25px;
          & img{
            height: max-content;
            width: max-content;
          }
          & p {
            padding: 10px 10px 0 0;
            font-weight: 700;
            font-size: 16px;
          }
        }

      }
    }
    &:nth-child(15){
      padding-bottom: 30px;
    }
    &:nth-child(16){
      width: 100%;
      height: 478px;
    }
    &:nth-child(17){
      padding-top: 40px;
      margin-bottom: 40px;
      & .about_company-block_content_right {
        padding-top: 15px;
      }
      p {
        padding-right: 0;
        padding-bottom: 15px;
        line-height: 1.3;
      }
      .tel{
        font-weight: 400;
        font-size: 22px;
      }
      button.btn{
        margin-top: 15px;
        background-color: transparent;
        border: 1px solid $main;
        color: $main;
        font-size: 11px;
        font-weight: 700;
        padding: 12px 20px;
        &:hover {
          background-color: $main;
          color: $white;
        }
      }
    }
  }
}
</style>
