/* eslint-disable import/prefer-default-export */
export const staticText = {
  search: {
    category: {
      ru: 'Категории',
      ua: 'Категорії',
      en: 'Categories',
    },
    products: {
      ru: 'Товары',
      ua: 'Товари',
      en: 'Products',
    },
    titlePrefix: {
      ru: 'Результат поиска',
      ua: 'Результат пошуку',
      en: 'Search results',
    },
  },
  delivery: {
    1: {
      ru: 'Самовывоз со склада',
      ua: 'Самовивіз зі склада',
      en: 'Pickup from the warehouse',
    },
    2: {
      ru: 'Доставка Новой Почтой',
      ua: 'Доставка Новою Поштою',
      en: 'Delivery by "Nova Poshta"',
    },
    3: {
      ru: 'Доставка на склад покупателя',
      ua: 'Доставка до складу покупця',
      en: 'Delivery to the buyer\'s warehouse',
    },
    4: {
      ru: 'Доставка ГК Алкотек',
      ua: 'Доставка ГК Алкотек',
      en: 'Delivery GoC Alkotek',
    },
  },
  registrationRight: {
    topText: {
      ru: 'Если перед Вами периодически возникает задача подготовить проект по кухонному оборудованию или мебели, приглашаем стать членом нашего клуба архитекторов и дизайнеров: предоставляемый нашей компанией пакет услуг избавит Вас от ненужных хлопот, переложив их на плечи профессионалов.',
      ua: 'Якщо перед Вами періодично виникає завдання підготувати проект щодо кухонного обладнання або меблів, запрошуємо стати членом нашого клубу архітекторів і дизайнерів: пакет послуг, що надається нашою компанією, позбавить Вас зайвого клопоту, перекладе їх на плечі професіоналів.',
      en: 'If you periodically have the task of preparing a project for kitchen equipment or furniture, we invite you to become a member of our club of architects and designers: the package of services provided by our company will save you from unnecessary troubles, shifting them to the shoulders of professionals.',
    },
    cardAdvantage: {
      ru: 'Преимущества клубной карты «Алкотек»:',
      ua: 'Переваги клубної карти «Алкотек»:',
      en: 'Benefits of the club card "Alcotech":',
    },
    cardAdvantageFirst: {
      ru: 'выгодные коммерческие условия по всему спектру наших товаров и услуг;',
      ua: 'вигідні комерційні умови по всьому спектру наших товарів і послуг;',
      en: 'favorable commercial conditions for the entire range of our goods and services;',
    },
    cardAdvantageSecond: {
      ru: 'доступ к участию в специальных торговых акциях;',
      ua: 'доступ до участі в спеціальних торгових акціях;',
      en: 'access to participation in special trading promotions;',
    },
    cardAdvantageThird: {
      ru: 'работа с персональным менеджером;',
      ua: 'робота з персональним менеджером;',
      en: 'work with a personal manager;',
    },
    cardAdvantageFourth: {
      ru: 'индивидуальные консультации в шоурумах компании;',
      ua: 'індивідуальні консультації в шоурумах компанії;',
      en: 'individual consultations in the showrooms of the company;',
    },
    cardAdvantageFifth: {
      ru: 'индивидуальная сервисная поддержка;',
      ua: 'індивідуальна сервісна підтримка;',
      en: 'individual service support;',
    },
    cardAdvantageSixth: {
      ru: 'логистическая, информационная и сервисная поддержка в регионах;',
      ua: 'логістична, інформаційна та сервісна підтримка в регіонах;',
      en: 'logistics, information and service support in the regions;',
    },
    cardAdvantageSeventh: {
      ru: 'возможность бесплатной рекламы на нашем сайте.',
      ua: 'можливість безкоштовної реклами на нашому сайті.',
      en: 'the possibility of free advertising on our website.',
    },
  },
  registrationForm: {
    items: {
      ru: [
        'Мебельные салоны и кухонные студии',
        'Архитекторы, дизайнеры, freelancers',
        'Мебельные интернет-магазины',
        'Интернет-магазины бытовой техники',
        'Интернет-магазины мульти-категорийные',
        'Интернет-магазины другой направленности',
        'Оптовые партнеры',
        'VIP клиент',
        'Сетевые партнеры (региональные и всеукраинские сети), Мегамаркеты сетевые',
        'Застройщики, Девелоперские организации',
      ],
      ua: [
        'Меблеві салони та кухонні студії',
        'Архітектори, дизайнеры, freelancers',
        'Меблеві інтернет-магазини',
        'Інтернет-магазини побутової техніки',
        'Інтернет-магазини мульти-категорійні',
        'Інтернет-магазини іншої спрямованості',
        'Оптові партнери',
        'VIP клієнт',
        'Мережеві партнери (регіональні та всеукраїнські мережі), Мегамаркети мережеві',
        'Забудовники, Девелоперські організації',
      ],
      en: [
        'Furniture showrooms and kitchen studios',
        'Architects, designers, freelancers',
        'Furniture online stores',
        'Online stores of home appliances',
        'Online stores multi-category',
        'Internet stores of a different orientation',
        'Wholesale partners',
        'VIP client',
        'Network partners (regional and all-Ukrainian networks), Megamarkets network',
        'Builders, Developer organizations',
      ],
    },
    tooltips: {
      vip: {
        ru: 'Уникальные условия покупки товаров',
        ua: 'Унікальні умови покупки товарів',
        en: 'Unique conditions for the purchase of goods',
      },
    },
    regTitle: {
      ru: 'Выберите наиболее подходящую для своего вида деятельности группу сотрудничества',
      ua: 'Оберіть найбільш прийнятну для свого виду діяльності групу співпраці',
      en: 'Choose the most suitable cooperation group for your type of activity',
    },
    allData: {
      ru: 'Основные данные',
      ua: 'Основні дані',
      en: 'Basic data',
    },
    nameSurname: {
      ru: 'ФИО*',
      ua: 'ПІБ*',
      en: 'Full Name*',
    },
    city: {
      ru: 'Ваш город*',
      ua: 'Ваше місто*',
      en: 'Your city*',
    },
    phoneNumber: {
      ru: 'Телефон',
      ua: 'Телефон',
      en: 'Phone',
    },
    email: {
      ru: 'Ваша электронная почта*',
      ua: 'Ваша електронна пошта*',
      en: 'Your email*',
    },
    reg: {
      ru: 'Зарегистрироваться',
      ua: 'Зареєструватися',
      en: 'Register',
    },
    placeholder: {
      ru: 'Фамилия Имя Отчество',
      ua: 'Прізвище Ім`я По батькові',
      en: 'Full Name',
    },
    placeholderCity: {
      ru: 'Киев',
      ua: 'Київ',
      en: 'Kyiv',
    },
  },
  passwordRecovery: {
    popupTitle: {
      ru: 'Восстановление пароля',
      ua: 'Відновлення паролю',
      en: 'Password recovery',
    },
    popupText: {
      ru: 'Введите адрес почты, который был использован при регистрации. Пароль вам будет отправлен автоматически',
      ua: 'Введіть адресу пошти, яка була використана при реєстрації. Пароль вам буде відправлено автоматично',
      en: 'Enter the email address that was used during registration. Your password will be sent to you automatically',
    },
    emailText: {
      ru: 'Ваша электронная почта',
      ua: 'Ваша електронна пошта',
      en: 'Your email',
    },
    sendText: {
      ru: 'Отправить',
      ua: 'Відправити',
      en: 'Send',
    },
  },
  login: {
    logTitle: {
      ru: 'Вход для клиентов',
      ua: 'Вхід для клієнтів',
      en: 'Client Login',
    },
    loginText: {
      ru: 'Логин',
      ua: 'Логін',
      en: 'Login',
    },
    passwordText: {
      ru: 'Пароль',
      ua: 'Пароль',
      en: 'Password',
    },
    recoveryPass: {
      ru: 'Забыли пароль?',
      ua: 'Забули пароль?',
      en: 'Forgot password?',
    },
    entrance: {
      ru: 'Войти',
      ua: 'Увійти',
      en: 'Sign in',
    },
    forClient: {
      ru: 'Хотите стать нашим клиентом?',
      ua: 'Бажаєте стати нашим клієнтом?',
      en: 'Do you want to become our client?',
    },
    forReg: {
      ru: 'Подать заявку',
      ua: 'Подати заявку',
      en: 'Apply',
    },
  },
  productTabs: {
    ru: ['Общее описание', 'Характеристики', 'Особенности'],
    ua: ['Загальний опис', 'Характеристики', 'Особливості'],
    en: ['General description', 'Characteristics', 'Features'],
  },
  sale: {
    1: {
      ru: 'До конца акции осталось:',
      ua: 'До кінця акції залишилося:',
      en: 'End of the promotion left:',
    },
    2: {
      ru: 'Дней',
      ua: 'Днів',
      en: 'Days',
    },
    3: {
      ru: 'Часов',
      ua: 'Годин',
      en: 'Hours',
    },
    4: {
      ru: 'Минут',
      ua: 'Хвилин',
      en: '',
    },
    5: {
      ru: 'Секунд',
      ua: 'Секунд',
      en: 'Minutes',
    },
  },
  tabs: [
    {
      link: 'projects',
      path: '/profile/projects',
      name: {
        ru: 'Проекты',
        ua: 'Проекти',
        en: 'Projects',
      },
    },
    {
      link: 'orders',
      path: '/profile/orders',
      name: {
        ru: 'Заказы поставщику',
        ua: 'Замовлення постачальнику',
        en: 'Orders to supplier',
      },
    },
    {
      link: 'training',
      path: '/profile/training',
      name: {
        ru: 'Онлайн курсы',
        ua: 'Онлайн курси',
        en: 'Online courses',
      },
    },
    /*
    {
      link: 'personalPromo',
      path: '/profile/promo',
      name: {
        ru: 'Персональные акции',
        ua: 'Персональні акції',
        en: 'Personal promo',
      },
    },
    */
    {
      link: 'configurator',
      path: '/profile/configurator',
      name: {
        ru: 'Конфигуратор',
        ua: 'Конфігуратор',
        en: 'Configurator',
      },
    },
  ],
  popups: {
    registrationSuccess: {
      popupTitle: {
        ru: 'Спасибо за Вашу заявку',
        ua: 'Дякуємо за Вашу заявку',
        en: 'Thank you for your application',
      },
      popupText: {
        ru: 'Ваш запрос будет обработан в течении 1го рабочего дня',
        ua: 'Ваш запит буде оброблено протягом 1 робочої доби',
        en: 'Your request will be processed within 1 business day',
      },
    },
    recoverySuccess: {
      popupTitle: {
        ru: 'Запрос отправлен',
        ua: 'Запит відправлено',
        en: 'Request has been sent',
      },
      popupText: {
        ru: 'Данные успешно сохранены',
        ua: 'Дані успішно збережено',
        en: 'Data saved successfully',
      },
    },
    1: {
      ru: 'Введите число от 1 до 9999.',
      ua: 'Введіть число від 1 до 9999.',
      en: 'Enter a number between 1 and 9999.',
    },
    2: {
      ru: 'Ваш новый пароль успешно сгенерирован и отправлен на почту.',
      ua: 'Ваш новий пароль успішно згенерований і відправлений на пошту.',
      en: 'Your new password has been successfully generated and sent to your email.',
    },
    3: {
      ru: 'Что-то пошло не так, попробуйте снова.',
      ua: 'Щось пішло не так, спробуйте знову.',
      en: 'Something went wrong, please try again.',
    },
    popupSave: {
      ru: 'Вы хотите сохранить текущий проект?',
      ua: 'Ви хочете зберегти поточний проект?',
      en: 'Do you want to save the current project?',
    },
    popupTitle: {
      ru: 'Вы уверены?',
      ua: 'Ви впевнені?',
      en: 'Are you sure?',
    },
    popupTitleProject: {
      ru: 'Вы уверены, что хотите удалить проект?',
      ua: 'Ви впевнені, що бажаєте видалити проект?',
      en: 'Are you sure you want to delete the project?',
    },
    popupTitleCompare: {
      ru: 'Выбранные для сравнения товары при переходе на другую категорию товаров  будут удалены из сравнения. Вы уверены?',
      ua: 'Обрані для порівняння товари при переході на іншу категорію товарів будуть видалені з порівняння. Ви впевнені?',
      en: 'The goods selected for comparison will be removed from the comparison when switching to another category of goods. Are you sure?',
    },
    popupTextYes: {
      ru: 'Да',
      ua: 'Так',
      en: 'Yes',
    },
    popupTextNo: {
      ru: 'Нет',
      ua: 'Ні',
      en: 'No',
    },
    popupJwt: {
      text: {
        ru: 'Сессия прервана, так как с вашей учетной записи, был осуществлен вход на другом устройстве',
        ua: 'Сесія перервана, так як з вашого облікового запису, був здійснений вхід на іншому пристрої',
        en: 'The session was interrupted because your account was signed in on another device',
      },
      btn: {
        ru: 'Понятно',
        ua: 'Зрозуміло',
        en: 'Clearly',
      },
    },
  },
  titles: {
    contactsKiev: {
      ru: 'Наши контакты в Киеве',
      ua: 'Наші контакти у Києві',
      en: 'Our contacts in Kyiv',
    },
    inDevelop: {
      ru: 'Страница находится в разработке',
      ua: 'Сторінка знаходиться у розробці',
      en: 'Page is under construction',
    },
    regTitle: {
      ru: 'Подача заявки на регистрацию',
      ua: 'Подання заявки на реєстрацію',
      en: 'Applying for registration',
    },
    action: {
      ru: 'Персональные акции',
      ua: 'Персональні акції',
      en: 'Personal promotions',
    },
    personalPromo: {
      ru: 'В акции участвуют:',
      ua: 'В акції беруть участь:',
      en: 'Participating in the promo:',
    },
    subTitle: {
      ru: 'Рекомендованная розничная цена',
      ua: 'Рекомендована роздрібна ціна',
      en: 'Recommended retail price',
    },
    error: {
      ru: 'Заполните все обязательные поля (*)',
      ua: 'Заповніть усі обов`язкові поля (*)',
      en: 'Fill in all required fields (*)',
    },
  },
  text: {
    1: {
      ru: 'С',
      ua: 'З',
      en: 'From',
    },
    2: {
      ru: 'После оформления заказа вы не сможете его изменить. Редактированию доступна только дата отгрузки.',
      ua: 'Після оформлення замовлення ви не зможете його змінити. Редагування доступна тільки дата відвантаження.',
      en: 'Once an order has been placed, you will not be able to change it. Only the shipping date is available for editing.',
    },
    rename: {
      ru: 'Переименовать',
      ua: 'Перейменувати',
      en: 'Rename',
    },
    disableRecommendation: {
      ru: 'Отключить рекомендации',
      ua: 'Відключити рекомендації',
      en: 'Disable recommendations',
    },
    allPromos: {
      ru: 'Все акции',
      ua: 'Всі акції',
      en: 'All promo',
    },
    whereToBuy: {
      ru: 'Где можно купить',
      ua: 'Де можна придбати',
      en: 'Where can I buy',
    },
    delivery: {
      1: {
        ru: 'Доставка бесплатная',
        ua: 'Доставка безкоштовна',
        en: 'Free delivery',
      },
      2: {
        ru: 'Стоимость доставки',
        ua: 'Вартість доставки',
        en: 'Cost of delivery',
      },
      3: {
        ru: 'Стоимость доставки уточняйте',
        ua: 'Вартість доставки уточнюйте',
        en: 'Check delivery cost',
      },
    },
  },
  btns: {
    1: {
      ru: 'Закрыть',
      ua: 'Закрити ',
      en: 'Close',
    },
    2: {
      ru: 'Купить на сайте магазина',
      ua: 'Купити на сайті магазина',
      en: 'Buy on the store website',
    },
    3: {
      ru: 'подробнее...',
      ua: 'детальніше...',
      en: 'more...',
    },
    4: {
      ru: 'Подробнее',
      ua: 'Детальніше',
      en: 'More...',
    },
    5: {
      ru: 'Добавить в проект',
      ua: 'Додати до проекту',
      en: 'Add to project',
    },
    6: {
      ru: 'Добавить к сравнению',
      ua: 'Додати до порівняння',
      en: 'Add to compare',
    },
    7: {
      ru: 'Перейти на товар',
      ua: 'Перейти до товару',
      en: 'Go to product',
    },
    8: {
      ru: 'Сравнить',
      ua: 'Порівняти',
      en: 'Compare',
    },
    btnCreate: {
      ru: 'Создать новый проект',
      ua: 'Cтворити новий проект',
      en: 'Create a new project',
    },
    9: {
      ru: 'Вернуться',
      ua: 'Повернутися',
      en: 'Return',
    },
    10: {
      ru: 'Подтвердить',
      ua: 'Підтвердити',
      en: 'Confirm',
    },
  },
  rigthSidebar: {
    recomendText: {
      ru: 'Рекомендации',
      ua: 'Рекомендації',
      en: 'Recommendations',
    },
    weRecommend: {
      ru: 'К этим товарам в проекте мы рекомендуем',
      ua: 'До цих товарів в проекті ми рекомендуємо',
      en: 'For these products in the project, we recommend',
    },
    closedSidebar: {
      ru: 'Товаров в проекте',
      ua: 'Товарів в проекті',
      en: 'Products in the project',
    },
    closedSidebarGuest: {
      ru: 'Товаров в корзине',
      ua: 'Товарів в кошику',
      en: 'Items in the basket',
    },
    placeholder: {
      ru: 'Краткий комментарий',
      ua: 'Короткий коментар',
      en: 'A short comment',
    },
    text: {
      ru: 'У вас пока не добавлено ни одного товара в новый проект. Выбрав любую категорию товара, она будет отображаться в этой панели.',
      ua: 'У вас поки не додано жодного товару в новий проект. Обравши будь-яку категорію товару, вона буде відображатися в цій панелі.',
      en: 'You have not added any products to the new project yet. Selecting any product category, it will be displayed in this panel.',
    },
    totalPrice: {
      ru: 'Итого:',
      ua: 'Всього:',
      en: 'Total',
    },
    button: {
      save: {
        ru: 'Сохранить',
        ua: 'Зберегти',
        en: 'Save',
      },
      check: {
        ru: 'Проверить',
        ua: 'Перевірити',
        en: 'Check',
      },
      guest: {
        ru: 'Оформить заказ',
        ua: 'Оформити замовлення',
        en: 'Checkout',
      },
    },
  },
  configurateArea: {
    closeFilterName: {
      ru: 'Фильтры',
      ua: 'Фільтри',
      en: 'Filters',
    },
    title: {
      ru: 'Категории товаров',
      ua: 'Категорії товарів',
      en: 'Products categories',
    },
    searchPlaceholder: {
      ru: 'Я ищу...',
      ua: 'Я шукаю...',
      en: 'I\'m looking for...',
    },
    searchTitle: {
      ru: 'История поиска',
      ua: 'Iсторія пошуку',
      en: 'Search history',
    },
    searchNoFind: {
      ru: 'По вашему запросу ничего не найдено',
      ua: 'За вашим запитом нічого не знайдено',
      en: 'Nothing found for your request',
    },
    filterText: {
      ru: 'Подобрано',
      ua: 'Підібрано',
      en: 'Selected',
    },
    filterTextFrom: {
      ru: 'товаров из',
      ua: 'товарів з',
      en: 'products from',
    },
    buttonClear: {
      ru: 'Сбросить',
      ua: 'Скинути',
      en: 'Reset',
    },
    buttonApply: {
      ru: 'Посмотреть',
      ua: 'Переглянути',
      en: 'Revise',
    },
    filterTextEnd: {
      ru: 'товаров',
      ua: 'товарів',
      en: 'products',
    },
  },
  headerTexts: {
    exchangeRate: {
      ua: 'Курс валют на сьогодні: ',
      ru: 'Курс валют на сегодня:',
      en: 'Exchange rates for today:',
    },
    dollarCurrency: {
      ua: 'Долар / Гривня',
      ru: 'Доллар / Гривна',
      en: 'USD / Hryvnia',
    },
    euroCurrency: {
      ua: 'Євро / Гривня',
      ru: 'Евро / Гривна',
      en: 'Euro / Hryvnia',
    },
    crossCurrency: {
      ua: 'Євро / Долар',
      ru: 'Евро / Доллар',
      en: 'Euro / USD',
    },
    menu: [
      {
        name: 'catalog',
        text: {
          ua: 'Каталог',
          ru: 'Каталог',
          en: 'Catalog',
        },
      },
      {
        name: 'promo',
        text: {
          ua: 'Акції',
          ru: 'Акции',
          en: 'Promo',
        },
      },
      {
        name: 'brands',
        text: {
          ua: 'Брендинг',
          ru: 'Бренды',
          en: 'Brands',
        },
        header: {
          ua: 'Торгові марки, продукцію яких пропонує ГК "Алкотек"',
          ru: 'Торговые марки, продукцию которых предлагает ГК "Алкотек"',
          en: 'Trademarks, the products of which are offered by Alkotek GoC',
        },
        description: {
          ua: 'Для отримання детальної інформації про бренд клацніть на логотип',
          ru: 'Для получения детальной информации о бренде кликните на логотип',
          en: 'For detailed information about the brand, click on the logo',
        },
      },
      {
        name: 'page/partners',
        text: {
          ua: 'Партнери',
          ru: 'Партнеры',
          en: 'Partners',
        },
      },
      {
        name: 'page/counseling',
        text: {
          ua: 'VIP',
          ru: 'VIP',
          en: 'VIP',
        },
      },
      /*
      {
        name: 'price',
        text: {
          ua: 'Залишки',
          ru: 'Остатки',
          en: 'Leavings',
        },
      },
      */
      {
        name: 'page/delivery',
        text: {
          ua: 'Доставка',
          ru: 'Доставка',
          en: 'Delivery',
        },
      },
      {
        name: 'contacts',
        text: {
          ua: 'Контакти',
          ru: 'Контакты',
          en: 'Contacts',
        },
      },
      {
        name: 'registration',
        text: {
          ua: 'Анкета',
          ru: 'Анкета',
          en: 'Form',
        },
      },
    ],
    profile: {
      login:
      {
        ua: 'Вхід до особистого кабінету',
        ru: 'Вход в личный кабинет',
        en: 'Login to personal account',
      },
      logout:
      {
        ua: 'Вихід',
        ru: 'Выход',
        en: 'Login',
      },
      languages: [
        {
          index: 'ru',
          text: 'РУС',
        },
        {
          index: 'ua',
          text: 'УКР',
        },
        {
          index: 'en',
          text: 'ENG',
        },
      ],
    },
    textBalance: {
      ua: 'Залишки',
      ru: 'Остатки',
      en: 'Leavings',
    },
    cookieText: {
      text: {
        ua: 'Користуючись сайтом Ви погоджуєтесь на збереження і обробку Cookie',
        ru: 'Пользуясь сайтом Вы соглашаетесь на сохранение и обработку Cookie',
        en: '',
      },
      btn: {
        ua: 'Прийняти',
        ru: 'Принять',
        en: 'Confirm',
      },
    },
    popupText: {
      popupTitle: {
        ru: 'Вы хотите сохранить текущий проект?',
        ua: 'Ви хочете зберегти поточний проект?',
        en: 'Do you want to save the current project?',
      },
      popupTextYes: {
        ru: 'Да',
        ua: 'Так',
        en: 'Yes',
      },
      popupTextNo: {
        ru: 'Нет',
        ua: 'Нi',
        en: 'No',
      },
    },
  },
  additionalMenu: {
    menu: [{
      name: 'about',
      text: {
        ua: 'Про компанію',
        ru: 'О компании',
        en: 'About',
      },
    },
    {
      name: 'news',
      text: {
        ua: 'Новини',
        ru: 'Новости',
        en: 'News',
      },
    },
    // TODO верстка страницы
    /*
    {
      name: 'career',
      text: {
        ua: 'Кар`єра з Alcotec',
        ru: 'Карьера с Alcotec',
        en: '',
      },
    },
    */
    {
      name: 'page/services',
      text: {
        ua: 'Сервіси',
        ru: 'Сервисы',
        en: 'Services',
      },
    },
    {
      name: 'page/partnership',
      text: {
        ua: 'Партнерські програми',
        ru: 'Партнерские программы',
        en: 'Partner programs',
      },
    },
    {
      name: 'page/contracts',
      text: {
        ua: 'Договори співпраці',
        ru: 'Договора сотрудничества',
        en: 'Cooperation agreements',
      },
    },
    {
      name: 'https://api.alcotec.ua/public/uploads/DesignBusiness.pdf',
      outside: true,
      text: {
        ua: 'Проектний бізнес',
        ru: 'Проектный бизнес',
        en: 'Project business',
      },
    }],
    footer: {
      ru: 'Все права защищены',
      ua: 'Усі права захищені',
      en: 'All rights reserved',
    },
    office: {
      placeholder: {
        ru: 'Киев (главный офис)',
        ua: 'Київ (головний офіс)',
        en: 'Kyiv (head office)',
      },
      ru: ['Харьков', 'Киев (главный офис)', 'Днепр', 'Львов', 'Тернополь', 'Кропивницкий', 'Винница', 'Одесса', 'Запорожье', 'Херсон', 'Николаев', 'Кременчуг', 'Луцк', 'Ужгород'],
      ua: ['Харків', 'Київ (головний офіс)', 'Дніпро', 'Львів', 'Тернопіль', 'Кропивницький', 'Вінниця', 'Одеса', 'Запоріжжя', 'Херсон', 'Миколаїв', 'Кременчук', 'Луцьк', 'Ужгород'],
      en: ['Kharkiv', 'Kyiv (main office)', 'Dnipro', 'Lviv', 'Ternopil', 'Kropivnytskyi', 'Vynnytsia', 'Odessa', 'Zaporozhye', 'Kherson', 'Mykolaiv', 'Kremenchug', 'Lutsk', 'Uzhhorod'],
    },
  },
  configurator: {
    textSort: {
      title: {
        ru: 'Сортировать по',
        ua: 'Сортувати за',
        en: 'Sort by',
      },
      rating: {
        ru: 'Рейтингу',
        ua: 'Рейтингом',
        en: 'Rating',
      },
      price: {
        ru: 'Цене',
        ua: 'Ціною',
        en: 'Price',
      },
      popular: {
        ru: 'Популярности',
        ua: 'Популярністю',
        en: 'Popularity',
      },
    },
  },
  footerTexts: {
    contacts: {
      title: {
        ru: 'Контакты',
        ua: 'Контакти',
        en: 'Сontacts',
      },
      items: [
        {
          title: {
            ru: 'Киев (главный офис)',
            ua: 'Київ (головний офіс)',
            en: 'Kyiv (main office)',
          },
          phone: '+38 (044) 500-26-26',
          address: {
            ru: '04119, ул. Дегтярёвская 25-А, ЖК "Cosmopolitan", корпус 1, секция 3, офис 413',
            ua: '04119, вул. Дегтярівська 25-А, ЖК "Cosmopolitan", корпус 1, секція 3, офіс 413',
            en: '',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Харьков',
            ua: 'Харків',
            en: 'Kharkiv',
          },
          phone: '+38 (050) 323-47-87',
          address: {
            ru: '61140, пр. Фонвизина 18, офис 215',
            ua: '61140, пр. Фонвізіна 18, офіс 215',
            en: '61140, 18 Fonvizina Ave., office 215',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Днепр',
            ua: 'Дніпро',
            en: 'Dnipro',
          },
          phone: '+38 (050) 356-64-12',
          address: {
            ru: '49044, ул. Гоголя,15В офис 18',
            ua: '49044, вул. Гоголя, 15В офіс 18',
            en: '49044, str. Gogolya, 15B, office 18',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Львов',
            ua: 'Львів',
            en: 'Lviv',
          },
          phone: '+38 (050) 443-77-31',
          address: {
            ru: '79053, ул. В. Великого 16, офис 501',
            ua: '79053, вул. В. Великого 16, офіс 501',
            en: '79053, str. V. Velikoho 16, office 501',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Тернополь',
            ua: 'Тернопіль',
            en: 'Ternopil',
          },
          phone: '+38 (095) 831-04-73',
          address: {
            ru: '46023, пр. Злуки 4, кв. 78',
            ua: '46023, пр. Злуки 4, кв. 78',
            en: '46023, 4 Zluki ave., sq. 78',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Кривой Рог',
            ua: 'Кривий Ріг',
            en: 'Kryvyi Rih',
          },
          phone: '+38 (050) 418-02-95',
          address: {
            ru: '-',
            ua: '-',
            en: '-',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Кропивницкий',
            ua: 'Кропивницький',
            en: 'Kropyvnytskyi',
          },
          phone: '+38 (050) 418-02-95',
          address: {
            ru: '25015, ул. Вячеслава Черновола 1Б',
            ua: '25015, вул. Вячеслава Чорновола 1Б',
            en: '25015, str. Vyacheslav Chornovola 1B',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Винница',
            ua: 'Вінниця',
            en: 'Vinnytsia',
          },
          phone: '+38 (067) 964-84-55',
          address: {
            ru: '21021, ул. 600-летия 17, корп. 21',
            ua: '21021, вул. 600-річчя 17, корп. 21',
            en: '21021, str. 600-anniversary 17, building 21',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Одесса',
            ua: 'Одеса',
            en: 'Odesa',
          },
          phone: '+38 (050) 386-09-50',
          address: {
            ru: '65009, ул. Фонтанская дорога 11, оф. 516',
            ua: '65009, вул. Фонтанська дорога 11, оф. 516',
            en: '65009, str. 11 Fontanska road, office 516',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Запорожье',
            ua: 'Запоріжжя',
            en: 'Zaporizhzhia',
          },
          phone: '+38 (050) 358-70-26',
          address: {
            ru: '69000, ул. Северное шоссе 4, каб. 307',
            ua: '69000, вул. Північне шосе 4, каб. 307',
            en: '69000, str. Northern highway 4, room 307',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Херсон',
            ua: 'Херсон',
            en: 'Kherson',
          },
          phone: '+38 (050) 418-02-95',
          address: {
            ru: '-',
            ua: '-',
            en: '-',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Николаев',
            ua: 'Миколаїв',
            en: 'Mykolaiv',
          },
          phone: '+38 (050) 418-02-95',
          address: {
            ru: '-',
            ua: '-',
            en: '-',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Кременчуг',
            ua: 'Кременчук',
            en: 'Kremenchuk',
          },
          phone: '+38 (050) 445-91-33',
          address: {
            ru: '39602, пр. Свободы 54-а',
            ua: '39602, пр. Свободи 54-а',
            en: '39602, 54-a Svoboda Ave',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Луцк',
            ua: 'Луцьк',
            en: 'Lutsk',
          },
          phone: '+38 (095) 831-04-73',
          address: {
            ru: '46023, пр. Злуки 4, кв. 78',
            ua: '46023, пр. Злуки 4, кв. 78',
            en: '46023, 4 Zluki ave., sq. 78',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Ужгород',
            ua: 'Ужгород',
            en: 'Uzhhorod',
          },
          phone: '+38 (095) 275-59-67',
          address: {
            ru: '-',
            ua: '-',
            en: '-',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
        {
          title: {
            ru: 'Донецк',
            ua: 'Донецьк',
            en: 'Donetsk',
          },
          phone: '+38 (050) 328-36-33',
          address: {
            ru: '-',
            ua: '-',
            en: '-',
          },
          days: {
            ru: 'ПН-ПТ:',
            ua: 'ПН-ПТ:',
            en: 'Mon-Fri:',
          },
          time: {
            ru: 'с 9-00 до 18-00',
            ua: 'з 9-00 до 18-00',
            en: 'from 9-00 to 18-00',
          },
        },
      ],
    },
    linksTitle: {
      ru: 'Полезные ссылки',
      ua: 'Корисні посилання',
      en: 'Useful links',
    },
    menu: [
      {
        name: 'about',
        text: {
          ru: 'О компании',
          ua: 'Про компанію',
          en: 'About',
        },
      },
      {
        name: 'page/purchase-returns',
        text: {
          ru: 'Условия возврата',
          ua: 'Умови повернення',
          en: 'Purchase returns',
        },
      },
      {
        name: 'news',
        text: {
          ru: 'Новости',
          ua: 'Новини',
          en: 'News',
        },
      },
      {
        name: 'page/payment',
        text: {
          ru: 'Условия оплаты',
          ua: 'Умови оплати',
          en: 'Terms of payment',
        },
      },
      // TODO верстка страницы карьеры
      /*
      {
        name: 'career',
        text: {
          ru: 'Карьера с Alcotec',
          ua: 'Кар`єра з Alcotec',
          en: 'career with Alcotec',
        },
      },
      */
      {
        name: 'page/services',
        text: {
          ru: 'Сервисы',
          ua: 'Сервіси',
          en: 'Services',
        },
      },
      {
        name: 'page/offers',
        text: {
          ru: 'Договор оферты',
          ua: 'Договір оферти',
          en: 'Offer contract',
        },
      },
      {
        name: 'page/partnership',
        text: {
          ru: 'Партнерские программы',
          ua: 'Партнерські програми',
          en: 'Partner programs',
        },
      },
      {
        name: 'page/contracts',
        text: {
          ru: 'Договора сотрудничества',
          ua: 'Договори співпраці',
          en: 'Cooperation agreements',
        },
      },
      {
        name: 'page/connecting-equipment',
        text: {
          ru: 'Услуга подключения',
          ua: 'Послуга підключення',
          en: 'Connection service',
        },
      },
      {
        name: 'page/confidentiality',
        text: {
          ru: 'Пользовательское соглашение',
          ua: 'Угода користувача',
          en: 'Confidentiality',
        },
      },
      {
        name: 'https://api.alcotec.ua/public/uploads/DesignBusiness.pdf',
        outside: true,
        text: {
          ua: 'Проектний бізнес',
          ru: 'Проектный бизнес',
          en: 'Project business',
        },
      },
      {
        name: 'https://api.alcotec.ua/public/news/emergency/',
        outside: true,
        text: {
          ua: 'Хронологія подій',
          ru: 'Хронология событий',
          en: 'Chronology of events',
        },
      },
    ],
    socialTitle: {
      ru: 'Присоединяйтесь к нам:',
      ua: 'Приєднуйся до нас:',
      en: 'Join us:',
    },
    rulesText: {
      ru: 'Alcotec.ua Все права защищены',
      ua: 'Alcotec.ua Усі права захищені',
      en: 'Alcotec.ua All rights rese',
    },
    devTitle: {
      ru: 'Разработка сайта:',
      ua: 'Розробка сайту:',
      en: 'Site development:',
    },
    footnote: {
      ru: '* Цены в валюте указаны в качестве индикативных. Оплата товара производится в национальной валюте по курсу на день оплаты.',
      ua: '* Ціни у валюті вказані в якості індикативних. Оплата товару проводиться в національній валюті за курсом на день оплати.',
      en: '* Prices in currency are indicative. Payment for the goods is made in the national currency at the exchange rate on the day of payment.',
    },
  },
  editPage: {
    title: {
      ru: 'Редактировать профиль',
      ua: 'Редагування профілю',
      en: 'Edit profile',
    },
    saveBtn: {
      ru: 'Сохранить изменения',
      ua: 'Зберегти зміни',
      en: 'Save сhanges',
    },
    generalTitle: {
      ru: 'Основные данные',
      ua: 'Основні дані',
      en: 'Main data',
    },
    partner: {
      ru: 'Контрагент',
      ua: 'Контрагент',
      en: 'Counterparty',
    },
    fullName: {
      ru: 'ФИО',
      ua: 'ПІБ',
      en: 'Full name',
    },
    phone: {
      ru: 'Телефон',
      ua: 'Телефон',
      en: 'Phone',
    },
    email: {
      ru: 'Ваша электронная почта',
      ua: 'Ваша електронна пошта',
      en: 'Your email',
    },
    address: {
      ru: 'Адрес',
      ua: 'Адреса',
      en: 'Address',
    },
    minMarga: {
      ru: 'Минимальная маржа, %',
      ua: 'Мінімальна маржа, %',
      en: 'Minimum margin, %',
    },
    manager: {
      ru: 'Ваш персональный менеджер',
      ua: 'Ваш персональний менеджер',
      en: 'Your personal manager',
    },
    optionProfile: {
      ru: 'Настройка профиля',
      ua: 'Настроювання профілю',
      en: 'Profile setup',
    },
    oldPassword: {
      ru: 'Старый пароль',
      ua: 'Старий пароль',
      en: 'Old password',
      text: {
        ru: 'Для смены пароля вначале введите старый пароль, потом новый',
        ua: 'Для зміни паролю спочатку введіть старий пароль, потім новий',
        en: 'To change your password, first enter the old password, then the new one.',
      },
    },
    newPassword: {
      ru: 'Новый пароль',
      ua: 'Новий пароль',
      en: 'New password',
    },
    newPasswordAgain: {
      ru: 'Новый пароль еще раз',
      ua: 'Новий пароль ще раз',
      en: 'New password again',
    },
    oldSite: {
      ru: 'Предыдущая версия сайта',
      ua: 'Попередня версія сайту',
      en: 'Previous site version',
    },
    promoCode: {
      ru: 'Промокод',
      ua: 'Промокод',
      en: 'Promo code',
    },
    optPriceGroupTitle: {
      ru: 'Видимость оптовых цен для менеджера',
      ua: 'Видимість оптових цін для менеджера',
      en: 'Visibility of wholesale prices for the manager',
    },
    optPriceVisibilityInItems: {
      ru: 'Оптовые цены в корзине и проекте',
      ua: 'Оптові ціни в кошику та проекті',
      en: 'Wholesale prices in the basket and project',
    },
    optPriceDownloadLink: {
      ru: 'Ссылка на скачивание оптового прайса',
      ua: 'Посилання на завантаження оптового прайсу',
      en: 'Link to download the wholesale price list',
    },
    pressForCopy: {
      ru: 'Нажмите чтобы скопировать',
      ua: 'Натиснiть щоб скопiювати',
      en: 'Click to copy',
    },
  },
  socialBlock: {
    text: {
      ua: 'Ставайте нашими клієнтами та вступайте до клубу архітекторів',
      ru: 'Становитесь нашими клиентами и вступайте в клуб архитекторов',
      en: 'Become our clients and join the architects\' club',
    },
    joinUs: {
      ru: 'Присоединяйтесь к нам',
      ua: 'Приєднуйтесь до нас',
      en: 'Join us',
    },
  },
  videosBlock: {
    title: {
      ua: 'Відео-огляди',
      ru: 'Видео-обзоры',
      en: 'Video reviews',
    },
    showAll: {
      ua: 'Побачити усі',
      ru: 'Посмотреть все',
      en: 'View all',
    },
    text: {
      ua: 'ASKO - шведське якість за ексклюзивними цінами',
      ru: 'ASKO - шведское качество по эксклюзивным ценам',
      en: 'ASKO - Swedish quality at exclusive prices',
    },
  },
  userMenu: [
    {
      name: 'projects',
      path: '/profile/projects',
      text: {
        ru: 'Проекты',
        ua: 'Проекти',
        en: 'Projects',
      },
    },
    {
      name: 'orders',
      path: '/profile/orders',
      text: {
        ru: 'Заказы поставщику',
        ua: 'Замовлення постачальнику',
        en: 'Orders to supplier',
      },
    },
    {
      name: 'training',
      path: '/profile/training',
      text: {
        ru: 'Онлайн курсы',
        ua: 'Онлайн курси',
        en: 'Online courses',
      },
    },
    /*
    {
      name: 'personalPromo',
      path: '/profile/promo',
      text: {
        ru: 'Персональные акции',
        ua: 'Персональні акції',
        en: 'Personal promo',
      },
    },
    */
    {
      name: 'configurator',
      path: '/profile/configurator',
      text: {
        ru: 'Конфигуратор',
        ua: 'Конфігуратор',
        en: 'Configurator',
      },
    },
    {
      name: 'edit',
      path: '/profile/edit',
      text: {
        ru: 'Редактировать профиль',
        ua: 'Редагування профілю',
        en: 'Profile edit',
      },
    },
  ],
  project: {
    titleProject: {
      ru: 'Товары в проекте №',
      ua: 'Товари у проекті №',
      en: 'Items in Project No.',
    },
    titleOrder: {
      ru: 'заказ №',
      ua: 'замовлення №',
      en: 'order no.',
    },
    titleSpecification: {
      ru: 'Спецификации по проекту:',
      ua: 'Специфікації за проектом:',
      en: 'Project specifications:',
    },
    titleSpecifications: {
      1: {
        title: {
          ru: 'Полная',
          ua: 'Повна',
          en: 'Full',
        },
        url: 'pdf-full',
        params: '',
      },
      2: {
        title: {
          ru: 'Краткая',
          ua: 'Коротка',
          en: 'Short',
        },
        url: 'pdf-min',
        params: '',
      },
      3: {
        title: {
          ru: 'Полная без цены',
          ua: 'Повна без ціни',
          en: 'Full without price',
        },
        url: 'pdf-full',
        params: 'noprice=1',
      },
      4: {
        title: {
          ru: 'Краткая без цены',
          ua: 'Коротка без ціни',
          en: 'Short without price',
        },
        url: 'pdf-min',
        params: 'noprice=1',
      },
      fio: {
        item: {
          ru: 'ФИО',
          ua: 'ПІБ',
          en: 'Full name',
        },
        title: {
          ru: 'Показывать ФИО в PDF',
          ua: 'Показувати ПІБ в PDF',
          en: 'Show Full name in PDF',
        },
      },
      dropdown: {
        save: {
          ru: 'Сохранить и скачать',
          ua: 'Зберегти та завантажити',
          en: 'Save and download',
        },
        download: {
          ru: 'Скачать',
          ua: 'Завантажити',
          en: 'Download',
        },
      },
    },
    commentProject: {
      ru: 'Комментарий к проекту',
      ua: 'Коментар до проекту',
      en: 'Project comment',
    },
    titlePrintSpecification: {
      ru: 'Заголовок для печати спецификации',
      ua: 'Заголовок для друку специфікації',
      en: 'Specification Print Header',
    },
    errorText: {
      ru: 'Недопустимый размер скидки',
      ua: 'Неприпустимий розмір знижки',
      en: 'Invalid discount amount',
    },
    titleAddProject: {
      ru: 'Добавить в проект еще один товар',
      ua: 'Додати до проекту ще один товар',
      en: 'Add another product to the project',
    },
    titleSum: {
      ru: 'Сумма:',
      ua: 'Сума:',
      en: 'Total:',
    },
    titleDiscount: {
      ru: 'Скидка:',
      ua: 'Знижка:',
      en: 'Discount:',
    },
    titlePurchase: {
      ru: 'Общая сумма закупки:',
      ua: 'Загальна сума закупівлі:',
      en: 'Total Purchase Amount:',
    },
    titleMargin: {
      ru: 'Общая маржа:',
      ua: 'Загальна маржа:',
      en: 'Total Margin:',
    },
    titleTotalProject: {
      ru: 'Общая сумма проекта:',
      ua: 'Загальна сума проекту:',
      en: 'Total amount of the project:',
    },
    titleButtonWithMargin: {
      ru: 'Убрать проверку',
      ua: 'Видалити перевірку',
      en: 'Remove check',
    },
    titleButtonWithoutMargin: {
      ru: 'Проверить проект',
      ua: 'Перевірити проект',
      en: 'Check project',
    },
    titleDelivery: {
      ru: 'Доставка:',
      ua: 'Доставка:',
      en: 'Delivery:',
    },
    titleCity: {
      ru: 'Город:',
      ua: 'Місто:',
      en: 'City:',
    },
    titleCitySelect: {
      ru: 'Выберите город...',
      ua: 'Виберіть місто...',
      en: 'Select city...',
    },
    titleStreet: {
      ru: 'Улица:',
      ua: 'Вулиця:',
      en: 'Street:',
    },
    titleBuilding: {
      ru: 'Дом:',
      ua: 'Будинок:',
      en: 'House:',
    },
    titleApp: {
      ru: 'Квартира/Офис:',
      ua: 'Квартира/Офіс:',
      en: 'Apartment/Office:',
    },
    titleStreetSelect: {
      ru: 'Выберите улицу...',
      ua: 'Виберіть вулицю...',
      en: 'Select a street...',
    },
    titleStreetNodata: {
      ru: 'Введите для поиска...',
      ua: 'Введіть для пошуку...',
      en: '',
    },
    titleCityNodata: {
      ru: 'Введите для поиска...',
      ua: 'Введіть для пошуку...',
      en: 'Enter to search...',
    },
    titlePostcode: {
      ru: 'Номер отделения:',
      ua: 'Номер відділення:',
      en: 'Warehouse number:',
    },
    titleConnection: {
      ru: 'Подключение:',
      ua: 'Підключення:',
      en: 'Connection:',
    },
    titleCount: {
      ru: 'единиц товара',
      ua: 'одиниць товару',
      en: 'number of products',
    },
    titleTotal: {
      ru: 'ИТОГО:',
      ua: 'ВСЬОГО:',
      en: 'TOTAL:',
    },
    titleProjectOrder: {
      ru: 'Оформление проекта №',
      ua: 'Оформлення проекту №',
      en: 'Project design No.',
    },
    titleProjectClientName: {
      ru: 'Покупатель',
      ua: 'Покупець',
      en: 'Customer',
    },
    titleProjectClientPhone: {
      ru: 'Телефон',
      ua: 'Телефон',
      en: 'Phone',
    },
    titleProjectClientEmail: {
      ru: 'Ваша электронная почта',
      ua: 'Ваша електронна пошта',
      en: 'Your email',
    },
    titleProjectOrderDate: {
      ru: 'Заказ на дату',
      ua: 'Замовлення на дату',
      en: 'Order for a date',
    },
    titleProjectDelivery: {
      ru: 'Доставка',
      ua: 'Доставка',
      en: 'Delivery',
    },
    titleProjectDeliveryLink: {
      ru: 'Посмотреть условия',
      ua: 'Переглянути умови',
      en: 'View conditions',
    },
    titleProjectOrderComment: {
      ru: 'Комментарии к заказу',
      ua: 'Коментарі до замовлення',
      en: 'Order comments',
    },
    titleProjectButtonSave: {
      ru: 'Сохранить',
      ua: 'Зберегти',
      en: 'Save',
    },
    titleProjectButtonSaveChanges: {
      ru: 'Сохранить изменения',
      ua: 'Зберегти зміни',
      en: 'Save changes',
    },
    titleProjectButtonSaveNewProject: {
      ru: 'Сохранить как новый проект',
      ua: 'Зберегти як новий проект',
      en: 'Save as new project',
    },
    titleProjectButtonCheckout: {
      ru: 'Оформить заказ',
      ua: 'Оформити замовлення',
      en: 'Checkout',
    },
    titleProjectOrderDesc: {
      ru: 'После оформления заказа выбранные в нем товары и его заказ на дату — изменениям не подлежат',
      ua: 'Після оформлення замовлення обрані в ньому товари та його замовлення на дату - змінам не підлягають',
      en: 'After placing an order, the goods selected in it and its order for the date are not subject to changes',
    },
    formValidationErrors: {
      all: {
        ru: 'Заполните все обязательные поля (*)',
        ua: 'Заповніть усі обов`язкові поля (*)',
        en: 'Fill in all required fields (*)',
      },
      phone: {
        ru: 'Заполните поле ТЕЛЕФОН',
        ua: 'Заповніть поле ТЕЛЕФОН',
        en: 'Fill in the field PHONE',
      },
      name: {
        ru: 'Заполните поле ПОКУПАТЕЛЬ',
        ua: 'Заповніть поле ПОКУПЕЦЬ',
        en: 'Fill in the field BUYER',
      },
      email: {
        ru: 'Заполните поле ЭЛЕКТРОННАЯ ПОЧТА',
        ua: 'Заповніть поле ЕЛЕКТРОННА ПОШТА',
        en: 'Fill in the field E-MAIL',
      },
      date: {
        ru: 'Выберите дату заказа',
        ua: 'Виберіть дату замовлення',
        en: 'Select order date',
      },
    },
    safariAlertText: {
      ru: 'Вы подтверждаете скачивание документа',
      ua: 'Ви підтверджуєте завантаження документу',
      en: 'You confirm the download of the document',
    },
    safariAlertBtnConfirm: {
      ru: 'Да',
      ua: 'Так',
      en: 'Yes',
    },
    safariAlertBtnCancel: {
      ru: 'Нет',
      ua: 'Ні',
      en: 'No',
    },
  },
  projects: {
    yes: {
      ru: 'Да',
      ua: 'Так',
      en: 'Yes',
    },
    no: {
      ru: 'Нет',
      ua: 'Нi',
      en: 'No',
    },
    sYes: {
      ru: 'да',
      ua: 'так',
      en: 'yes',
    },
    sNo: {
      ru: 'нет',
      ua: 'нi',
      en: 'no',
    },
    sAll: {
      ru: 'все',
      ua: 'усi',
      en: 'all',
    },
    placeholder: {
      ru: 'Ваш поисковый запрос...',
      ua: 'Ваш пошуковий запит...',
      en: 'Your search query...',
    },
    title: {
      ru: 'Проекты',
      ua: 'Проекти',
      en: 'Projects',
    },
    notification: {
      desc: {
        ru: 'У вас есть наступившие заказы.',
        ua: 'У вас є замовлення, що настали.',
        en: 'You have pending orders.',
      },
      link: {
        ru: 'Перейти в заказы',
        ua: 'Перейти до замовлень',
        en: 'Go to orders',
      },
    },
    order: {
      ru: 'ЗАЯВКА',
      ua: 'ЗАМОВЛЕННЯ',
      en: 'ORDER',
    },
    th: [
      {
        ru: '№ Проекта',
        ua: '№ Проекту',
        en: 'Project No',
      },
      {
        ru: '№ Заказа',
        ua: '№ Замовлення',
        en: 'Order No.',
      },
      {
        ru: 'Дата проекта',
        ua: 'Дата проекту',
        en: 'Project date',
      },
      {
        ru: 'Клиент',
        ua: 'Клієнт',
        en: 'Client',
      },
      {
        ru: 'Товар',
        ua: 'Товар',
        en: 'Product',
      },
      {
        ru: 'Сумма проекта',
        ua: 'Сума проекту',
        en: 'Project amount',
      },
      {
        ru: 'Комментарий',
        ua: 'Коментар',
        en: 'Comment',
      },
      {
        ru: 'Промокод',
        ua: 'Промокод',
        en: 'Promo code',
      },
      {
        ru: 'Контрагент',
        ua: 'Контрагент',
        en: 'Counterparty',
      },
    ],
  },
  orders: {
    title: {
      ru: 'Заказы поставщику',
      ua: 'Замовлення постачальнику',
      en: 'Orders to supplier',
    },
    yes: {
      ru: 'Да',
      ua: 'Так',
      en: 'Yes',
    },
    no: {
      ru: 'Нет',
      ua: 'Нi',
      en: 'No',
    },
    th: [
      {
        ru: '№ Проекта',
        ua: '№ Проекту',
        en: 'Project No',
      },
      {
        ru: '№ Заказа',
        ua: '№ Замовлення',
        en: 'Order No.',
      },
      {
        ru: 'Сумма заказа, грн.',
        ua: 'Сума замовлення, грн.',
        en: 'Order amount, UAH',
      },
      {
        ru: 'Сумма закупки, грн.',
        ua: 'Сума закупівлі, грн.',
        en: 'Purchase amount, UAH',
      },
      {
        ru: 'Заказ на дату',
        ua: 'Замовлення на дату',
        en: 'Order for a date',
      },
      {
        ru: 'Статус заказа',
        ua: 'Статус замовлення',
        en: 'Order status',
      },
      {
        ru: 'Комментарий',
        ua: 'Коментар',
        en: 'Comment',
      },
      {
        ru: 'Оплачено',
        ua: 'Сплачено',
        en: 'Paid',
      },
      {
        ru: 'Доход, грн.',
        ua: 'Дохід, грн.',
        en: 'Income, UAH',
      },
      {
        ru: 'Промокод',
        ua: 'Промокод',
        en: 'Promo code',
      },
      {
        ru: 'Контрагент',
        ua: 'Контрагент',
        en: 'Counterparty',
      },
    ],
  },
  product: {
    recomPrice: {
      ru: 'Рекомендованная розничная цена',
      ua: 'Рекомендована роздрібна ціна',
      en: 'Recommended retail price',
    },
    selectCurrency: {
      ru: ['Гривна', 'Доллар', 'Евро'],
      ua: ['Гривня', 'Долар', 'Євро'],
      en: ['Hryvnia', 'USD', 'Euro'],
    },
    setSliderTitle: {
      ru: 'Комплектом дешевле',
      ua: 'Комплектом дешевше',
      en: 'The set is cheaper',
    },
    buttons: {
      buy: {
        ru: 'Купить',
        ua: 'Купити',
        en: 'Buy',
      },
      addComparison: {
        ru: 'Добавить к сравнению',
        ua: 'Додати до порівняння',
        en: 'Add to compare',
      },
      addInProject: {
        ru: 'Добавить в проект',
        ua: 'Додати до проекту',
        en: 'Add to project',
      },
      addSet: {
        ru: 'Добавить',
        ua: 'Додати',
        en: 'Add',
      },
    },
    otherModel: {
      ru: 'Другие модели',
      ua: 'Інші моделі',
      en: 'Other models',
    },
    itemsFromCollection: {
      ru: 'Товары из этой коллекции',
      ua: 'Товари з цієї колекції',
      en: 'Products from this collection',
    },
    notAvailable: {
      ru: 'Товар временно недоступен',
      ua: 'Товар тимчасово недоступний',
      en: 'Product temporarily unavailable',
    },
    notAvailableSet: {
      ru: 'Комплект временно не доступен',
      ua: 'Комплект тимчасово не доступний',
      en: 'The set is temporarily unavailable',
    },
    marginalityFail: {
      ru: 'несоответствие маржинальности',
      ua: 'невідповідність маржинальності',
      en: 'margin mismatch',
    },
    basketAddError: {
      ru: 'Ошибка добавления в корзину',
      ua: 'Помилка додавання в кошик',
      en: 'Error adding to cart',
    },
  },
  categoryItem: {
    subTitle: {
      ru: 'Рекомендованная розничная цена',
      ua: 'Рекомендована роздрібна ціна',
      en: 'Recommended retail price',
    },
    btn: {
      ru: 'Добавить в проект',
      ua: 'Додати до проекту',
      en: 'Add to project',
    },
    checkbox: {
      ru: 'Добавить к сравнению',
      ua: 'Додати до порівняння',
      en: 'Add to compare',
    },
    activeCheckbox: {
      ru: 'Добавлен к сравнению',
      ua: 'Доданий до порівняння',
      en: 'Added to comparison',
    },
  },
  comparison: {
    titleText: {
      ru: 'Сравнение товаров',
      ua: 'Порівняння товарів',
      en: 'Product comparison',
    },
    addedProduct: {
      ru: 'Добавить к сравнению еще один товар',
      ua: 'Додати до порівняння ще один товар',
      en: 'Add another product to compare',
    },
    clearList: {
      ru: 'Очистить список',
      ua: 'Очистити список',
      en: 'Clear the list',
    },
    ViewForPrinting: {
      ru: 'Вид для печати',
      ua: 'Вид для друку',
      en: 'Print view',
    },
    checkBoxAllFields: {
      ru: 'Все поля',
      ua: 'Усі поля',
      en: 'All fields',
    },
    checkBoxProductDifferences: {
      ru: 'Различия в товарах',
      ua: 'Відмінності у товарах',
      en: 'Differences in products',
    },
  },
  review: {
    sticker: {
      ru: 'Отзыв',
      ua: 'Відгук',
      en: 'Review',
    },
    reviewTitle: {
      ru: 'Тестирование функционала',
      ua: 'Тестування функціоналу',
      en: 'Functional testing',
    },
    chooseTitle: {
      ru: 'Выберите вариант',
      ua: 'Виберіть варіант',
      en: 'Choose an option',
    },
    chooseOption: {
      ru: [
        'Отзыв',
        'Пожелания',
        'Ошибка',
      ],
      ua: [
        'Відгук',
        'Побажання',
        'Помилка',
      ],
      en: [
        'Review',
        'Wishes',
        'Error',
      ],
    },
    moreDetailTitle: {
      ru: 'Опишите подробнее',
      ua: 'Опишіть детальніше',
      en: 'Describe in more detail',
    },
    submit: {
      ru: 'Отправить',
      ua: 'Відправити',
      en: 'Send',
    },
    success: {
      ru: 'Спасибо за Ваш отзыв!',
      ua: 'Дякуємо за Ваш відгук!',
      en: 'Thanks for your feedback!',
    },
  },
  tutorial: {
    sticker: {
      ru: 'Обучение',
      ua: 'Навчання',
      en: 'Training',
    },
    tutorialMenu: {
      ru: 'Обучение работы с порталом',
      ua: 'Навчання роботи з порталом',
      en: 'Portal training',
    },
    tutorialTitle: {
      ru: 'Презентация функционала',
      ua: 'Презентація функціоналу',
      en: 'Portal functionality',
    },
    tutorialExternalUrl: {
      ru: 'Просмотреть видео на YouTube',
      ua: 'Переглянути відео на YouTube',
      en: 'Watch the video on YouTube',
    },
  },
  fastBuy: {
    btn: {
      ru: 'Купить без регистрации',
      ua: 'Купити без реєстрації',
      en: 'Buy without registration',
    },
    errorPhone: {
      length: {
        ru: 'Некорректный номер',
        ua: 'Некоректний номер',
        en: 'Invalid number',
      },
    },
    success: {
      ru: 'Спасибо, менеджер с Вами свяжется!',
      ua: 'Дякуємо, менеджер з Вами зв`яжеться!',
      en: 'Thank you, the manager will contact you!',
    },
  },
  guestBuy: {
    title: {
      ru: 'Оформление заказа на сумму',
      ua: 'Оформлення замовлення на суму',
      en: 'Placing an order for the amount',
    },
    btn: {
      ru: 'Оформить',
      ua: 'Оформити',
      en: 'Checkout',
    },
    text: {
      ru: 'Комментарий',
      ua: 'Коментар',
      en: 'Comment',
    },
    address: {
      ru: 'Адрес доставки',
      ua: 'Адреса доставки',
      en: 'Delivery address',
    },
    email: {
      ru: 'E-mail',
      ua: 'E-mail',
      en: 'E-mail',
    },
    phone: {
      ru: 'Телефон',
      ua: 'Телефон',
      en: 'Phone',
    },
    name: {
      ru: 'ФИО',
      ua: 'ПІБ',
      en: 'Full name',
    },
    emptyBasket: {
      ru: 'У вас пока не добавлено ни одного товара в корзину',
      ua: 'У вас поки не додано ні одного товару в кошик',
      en: 'You have not added any items to your cart yet',
    },
    inBasket: {
      ru: 'В корзине',
      ua: 'В кошику',
      en: 'In the card',
    },
    promocode: {
      ru: 'Введите промокод',
      ua: 'Введiть промокод',
      en: 'Enter promo code',
    },
  },
  training: {
    registration: {
      regTitle: {
        ru: 'Подача заявки на обучение',
        ua: 'Подача заявки на навчання',
        en: 'Apply for training',
      },
      regDescription: {
        ru: 'Выберите интересующую Вас тему или курс для он-лайн обучения',
        ua: 'Виберіть цікаву для Вас тему або курс для он-лайн навчання',
        en: 'Select the topic or course you are interested in for online learning',
      },
      regForm: {
        ru: 'Зарегистрируйтесь для прохождения онлайн обучения по выбранной теме',
        ua: 'Зареєструйтесь для проходження онлайн навчання за обраною темою',
        en: 'Register for online training on the chosen topic',
      },
      regRightDescriptionOne: {
        ru: 'Погрузитесь в мир инноваций и изобретений. Техника, которой наполнены дома людей по всему миру, почему ей отдают предпочтение тысячи и миллионы?',
        ua: 'Пориньте в світ інновацій та винаходів. Техніка, якою наповнені будинки людей по всьому світу, чому їй віддають перевагу тисячі і мільйони?',
        en: 'Immerse yourself in a world of innovation and invention. Technology, which is filled with people\'s homes around the world, why is it preferred by thousands and millions?',
      },
      regRightDescriptionTwo: {
        ru: 'На нашем портале Вы сможете получить доступ к обучающим тренингам, презентациям, мастер-классам, он-лайн курсам, вебинарам от первоисточников мировых производителей бытовой техники.',
        ua: 'На нашому порталі Ви зможете отримати доступ до навчальних тренінгів, презентацій, майстер-класів, он-лайн курсів, вебінарів від першоджерел світових виробників побутової техніки.',
        en: 'On our portal you can get access to trainings, presentations, master classes, online courses, webinars from the primary sources of world manufacturers of household appliances.',
      },
      regRightSubtitle: {
        ru: 'Вам будет доступна:',
        ua: 'Вам буде доступна:',
        en: 'You will have access to:',
      },
      regRightPointOne: {
        ru: 'Вся актуальная и необходимая для продаж информация',
        ua: 'Вся актуальна і необхідна для продажів інформація',
        en: 'All relevant and necessary information for sales',
      },
      regRightPointTwo: {
        ru: 'Ясная аргументация от производителей',
        ua: 'Ясна аргументація від виробників',
        en: 'Clear arguments from manufacturers',
      },
      regRightPointThree: {
        ru: 'Доступные и удобные форматы',
        ua: 'Доступні і зручні формати',
        en: 'Accessible and convenient formats',
      },
    },
  },
  autoSelection: {
    label: {
      ru: 'Автоподбор',
      ua: 'Автопідбір',
      en: 'Auto-selection',
    },
    title: {
      ru: 'Автоматический подбор техники',
      ua: 'Автоматичний підбір техніки',
      en: 'Automatic selection of equipment',
    },
    btn: {
      ru: 'Добавить в проэкт',
      ua: 'Додати до поекту',
      en: 'Add to project',
    },
    btnAddCount: {
      ru: 'Добавить товар',
      ua: 'Додати товар',
      en: 'Add item',
    },
    btnGetProducts: {
      ru: 'Сделать подбор техники',
      ua: 'Зробити підбір техніки',
      en: 'Make a selection of equipment',
    },
    inputsPlaceholders: {
      cat: {
        ru: 'Категория',
        ua: 'Категорія',
        en: 'Category',
      },
      subCat: {
        ru: 'Подкатегория',
        ua: 'Підкатегорія',
        en: 'Subcategory',
      },
      brand: {
        ru: 'Бренд',
        ua: 'Бренд',
        en: 'Brand',
      },
      type: {
        ru: 'Тип',
        ua: 'Тип',
        en: 'Type',
      },
      color: {
        ru: 'Цвет',
        ua: 'Колір',
        en: 'Color',
      },
    },
    checkbox: {
      ru: 'Отметить для добавления',
      ua: 'Відмітити для додавання',
      en: 'Check to add',
    },
    checkboxActive: {
      ru: 'Отмечен для добавления',
      ua: 'Відмічений для додавання',
      en: 'Checked for addition',
    },
    noOptions: {
      ru: 'Нет данных',
      ua: 'Данні відсутні',
      en: 'No data',
    },
    priceRangeTitle: {
      ru: 'Приблизительный бюджет на каждый товар',
      ua: 'Приблизний бюджет на кожен товар',
      en: 'Approximate budget for each product',
    },
  },
  promotions: {
    btnArchive: {
      ru: 'Архив акций',
      ua: 'Архів акцій',
      en: 'Promo archive',
    },
  },
  slider: {
    btn: {
      ru: 'Скачать инструкцию',
      ua: 'Завантажити iнструкцiю',
      en: 'Download instructions',
    },
    madeIn: 'Made in',
  },
  pagination: {
    arrowNext: {
      ru: 'Следующая',
      ua: 'Наступна',
      en: 'Next',
    },
    arrowPrev: {
      ru: 'Предыдущая',
      ua: 'Попередня',
      en: 'Prev',
    },
  },
  pageNotFound: {
    btnCreateUser: {
      ru: 'Проекты',
      ua: 'Проекти',
      en: 'Projects',
    },
    btnCreate: {
      ru: 'Вернутся на главную',
      ua: 'Повернутися на головну',
      en: 'Back to home',
    },
    errorText: {
      ru: 'ошибка',
      ua: 'помилка',
      en: 'error',
    },
    pageNotFoundText: {
      ru: 'Извините, мы не нашли эту страницу! Может быть, ссылка устарела или страница удалена.',
      ua: 'Вибачте, ми не знайшли цієї сторінки! Можливо, посилання застаріле або сторінку видалено.',
      en: 'Sorry, we didn\'t find this page! Maybe the link is out of date or the page has been removed.',
    },
  },
};
