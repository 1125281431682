<template>
  <section class="section">
    <div class="custom-container">
      <header class="project__header header">
        <div class="header__left">
          <ArrowGoBack :back-route-path="backRoutePath" />
          <span v-if="project.is_order" class="project__title">
            {{ staticText.project.titleProject[actualLang.index] }}
            {{ project.id }}
            ({{ staticText.project.titleOrder[actualLang.index] }}
            {{ project.order_id }})
          </span>
          <span v-else class="project__title">
            {{ staticText.project.titleProject[actualLang.index] }}
            {{ project.id }}
          </span>
        </div>
        <div class="header__right specifications">
          <div class="specifications__title">
            {{ staticText.project.titleSpecification[actualLang.index] }}
          </div>
          <ul class="specifications__list">
            <li class="specifications__item">
              <check-box
                v-model="fio"
                :text="staticText.project.titleSpecifications.fio.item[actualLang.index]"
                :red="true"
                :title="staticText.project.titleSpecifications.fio.title[actualLang.index]"
              />
            </li>
            <li class="specifications__item">
              <a
                class="specifications__link"
                @click.stop="dropdownPdf('full')"
              >
                <span class="icon-pdf-icon specifications__img"></span>
                <span>
                  {{ staticText.project.titleSpecifications[1].title[actualLang.index] }}
                </span>
                <ul
                  class="buttons__dropdown dropdown"
                  :class="{ dropdown_active: dropdownPdfFull }"
                >
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-full/${project.id}?${fio?'':'nofio=1&'}`,
                          true)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.save[actualLang.index] }}
                    </button>
                  </li>
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-full/${project.id}?${fio?'':'nofio=1&'}`,
                          false)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.download[actualLang.index] }}
                    </button>
                  </li>
                </ul>
              </a>
            </li>

            <li class="specifications__item">
              <a
                class="specifications__link"
                @click.stop="dropdownPdf('min')"
              >
                <span class="icon-pdf-icon specifications__img"></span>
                <span>
                  {{
                    staticText.project.titleSpecifications[2].title[
                      actualLang.index
                    ]
                  }}
                </span>
                <ul
                  class="buttons__dropdown dropdown"
                  :class="{ dropdown_active: dropdownPdfMin }"
                >
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-min/${project.id}?${fio?'':'nofio=1&'}`,
                          true)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.save[actualLang.index] }}
                    </button>
                  </li>
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-min/${project.id}?${fio?'':'nofio=1&'}`,
                          false)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.download[actualLang.index] }}
                    </button>
                  </li>
                </ul>
              </a>
            </li>

            <li class="specifications__item">
              <a
                class="specifications__link"
                @click.stop="dropdownPdf('full_no')"
              >
                <span class="icon-pdf-icon specifications__img"></span>
                <span>
                  {{
                    staticText.project.titleSpecifications[3].title[
                      actualLang.index
                    ]
                  }}
                </span>
                <ul
                  class="buttons__dropdown dropdown"
                  :class="{ dropdown_active: dropdownPdfFullNoPrice }"
                >
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-full/${project.id}?noprice=1&${fio?'':'nofio=1&'}`,
                          true)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.save[actualLang.index] }}
                    </button>
                  </li>
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-full/${project.id}?noprice=1&${fio?'':'nofio=1&'}`,
                          false)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.download[actualLang.index] }}
                    </button>
                  </li>
                </ul>
              </a>
            </li>

            <li class="specifications__item">
              <a
                class="specifications__link"
                @click.stop="dropdownPdf('min_no')"
              >
                <span class="icon-pdf-icon specifications__img"></span>
                <span>
                  {{
                    staticText.project.titleSpecifications[4].title[
                      actualLang.index
                    ]
                  }}
                </span>
                <ul
                  class="buttons__dropdown dropdown"
                  :class="{ dropdown_active: dropdownPdfMinNoPrice }"
                >
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-min/${project.id}?noprice=1&${fio?'':'nofio=1&'}`,
                          true)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.save[actualLang.index] }}
                    </button>
                  </li>
                  <li class="dropdown__item">
                    <button
                      type="button"
                      class="form__btn"
                      @click.prevent="
                        getPdfHandler(
                          'project',
                          `${baseDomain}/project/pdf-min/${project.id}?noprice=1&${fio?'':'nofio=1&'}`,
                          false)
                      "
                    >
                      {{ staticText.project.titleSpecifications.dropdown.download[actualLang.index] }}
                    </button>
                  </li>
                </ul>
              </a>
            </li>
          </ul>
        </div>
      </header>
      <main class="main">
        <ul class="project__comments comments">
          <li class="comments__item">
            <div class="comments__title">
              {{ staticText.project.commentProject[actualLang.index] }}
            </div>
            <label class="comments__label">
              <input
                v-model="project.comment"
                type="text"
                class="comments__input input"
              />
              <span class="icon-icon-pencil comments__icon"></span>
            </label>
          </li>
          <li class="comments__item">
            <div class="comments__title">
              {{ staticText.project.titlePrintSpecification[actualLang.index] }}
            </div>
            <label class="comments__label">
              <input
                v-model="project.title"
                type="text"
                class="comments__input input"
              />
              <span class="icon-icon-pencil comments__icon"></span>
            </label>
          </li>
        </ul>

        <div class="project__wrapper">
          <table class="project__table table">
            <thead class="table__head">
              <tr>
                <th v-for="(th, index) in titlesTable" :key="index">
                  {{ th }}
                </th>
              </tr>
            </thead>
            <tbody class="table__body">
              <tr
                v-for="(product, index) in project.products"
                :key="product.id"
                class="product"
                :class="{ 'out-of-stock': product.out_of_stock }"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <router-link
                    :to="
                      `/profile/configurator/category/${product.category_id}/product/${product.id}`
                    "
                    class="product__name"
                  >
                    <div class="product__preview">
                      <img
                        class="product__img"
                        :src="`${baseDomainImg}${product.img}`"
                        alt="preview"
                      />
                    </div>
                    <div class="product__text">
                      {{ product.name }}
                    </div>
                  </router-link>
                </td>
                <td>{{ product.program }}</td>
                <td :class="{ 'line-through' : (project.isSetDiscount === true && (product.setPrice && testArrayIncludes(product.setIds)))}">
                  {{ product.sale ? product.sale : product.price }}
                </td>
                <td
                  v-if="project.isSetDiscount === true"
                  :class="{ 'line-through' : !(product.setPrice && testArrayIncludes(product.setIds))}"
                >
                  {{ product.setPrice ? product.setPrice : '' }}
                </td>
                <td>
                  <div class="product__amount">
                    <button
                      v-if="!project.is_order"
                      class="product__button product__button_dec"
                      @click="
                        product.amount =
                          product.amount > 0 ? --product.amount : 0
                      "
                    ></button>
                    <span class="product__text">{{ product.amount }}</span>
                    <button
                      v-if="!project.is_order"
                      class="product__button"
                      @click="product.amount++"
                    ></button>
                  </div>
                </td>
                <td class="product__discount">
                  <div
                    class="discount"
                    :class="{ discount_error: discountError[index], disabled: ((project.hasPromoCode && $store.state.users.userData.role == 'manager') || (product.purchase > ((product.setPrice && testArrayIncludes(product.setIds)) ? product.setPrice : (product.sale ? product.sale : product.price))))}"
                  >
                    <input
                      v-model="product.discount"
                      type="text"
                      class="product__input input input_form"
                      placeholder="0.0"
                      pattern="[0-9.]*"
                      :readonly="((project.hasPromoCode && $store.state.users.userData.role == 'manager') || (product.purchase > ((product.setPrice && testArrayIncludes(product.setIds)) ? product.setPrice : (product.sale ? product.sale : product.price))) || project.is_order)"
                      :disabled="((project.hasPromoCode && $store.state.users.userData.role == 'manager') || (product.purchase > ((product.setPrice && testArrayIncludes(product.setIds)) ? product.setPrice : (product.sale ? product.sale : product.price))) || project.is_order)"
                      @keydown="keyDown"
                      @keyup="keyUp($event, index)"
                      @focus="focusDiscount($event)"
                    />
                    <div class="discount__error">
                      {{ staticText.project.errorText[actualLang.index] }}
                    </div>
                  </div>
                </td>
                <td :class="{ 'line-through' : (project.isSetDiscount === true && (product.setPrice && testArrayIncludes(product.setIds)))}">
                  {{ Math.round(calculateTotalPriceSale(product)) }}
                </td>
                <td
                  v-if="project.isSetDiscount === true"
                  :class="{ 'line-through' : !(product.setPrice && testArrayIncludes(product.setIds))}"
                >
                  {{ product.setPrice ? Math.round(calculateTotalPriceSet(product)) : '' }}
                </td>
                <td v-if="margin">
                  {{ product.purchase * product.amount }}
                </td>
                <td v-if="margin">
                  {{ product.amount ? calculateProductMargin(product) : 0 }}
                </td>
                <td>
                  <div class="product__complectation">
                    <select v-model="product.complectation_id" class="select" :disabled="project.is_order">
                      <option
                        v-for="(complectation, i) in complectations"
                        :key="i"
                        :value="complectation.id"
                        :selected="
                          complectation.id === product.complectation_id
                        "
                      >
                        {{ complectation.name[actualLang.index] }}
                      </option>
                    </select>
                    <check-box v-if="!project.is_order" v-model="product.active" :disable="projectHasSets" />
                  </div>
                </td>
                <td>
                  <button
                    v-if="!project.is_order"
                    type="button"
                    class="product__delete"
                    @click="deleteProduct(product.id)"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="!project.is_order"
          class="project__add add"
          @click.prevent="handlerAddProduct(project)"
        >
          <a href class="add__link">
            <span class="add__icon"></span>
            {{ staticText.project.titleAddProject[actualLang.index] }}
          </a>
        </div>
        <div class="project__price price">
          <table class="price__table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <radio-button value="main" :name="radio" text="Основа" />
                </th>
                <th>
                  <radio-button
                    value="option1"
                    :name="radio"
                    text="Вариант 1"
                  />
                </th>
                <th>
                  <radio-button
                    value="option2"
                    :name="radio"
                    text="Вариант 2"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ staticText.project.titleSum[actualLang.index] }}</td>
                <td>{{ sumProject.main }} {{ currency.ua }}</td>
                <td>{{ sumProject.option1 }} {{ currency.ua }}</td>
                <td>{{ sumProject.option2 }} {{ currency.ua }}</td>
              </tr>
              <tr>
                <td>
                  {{ staticText.project.titleDiscount[actualLang.index] }}
                </td>
                <td>
                  <div class="price__discount">
                    <input
                      :value="sumProject.mainDiscount"
                      type="text"
                      class="price__input input input_form"
                      :readonly="true"
                    />
                  </div>
                </td>
                <td>
                  <div class="price__discount">
                    <input
                      :value="sumProject.option1Discount"
                      type="text"
                      class="price__input input input_form"
                      :readonly="true"
                    />
                  </div>
                </td>
                <td>
                  <div class="price__discount">
                    <input
                      :value="sumProject.option2Discount"
                      type="text"
                      class="price__input input input_form"
                      :readonly="true"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="margin
            && ((userData.role === 'manager' && userData.optPriceVisibilityInItems) || userData.role === 'director')">
                <td>
                  {{ staticText.project.titlePurchase[actualLang.index] }}
                </td>
                <td>{{ sumProject.mainPurchase }} {{ currency.ua }}</td>
                <td>{{ sumProject.option1Purchase }} {{ currency.ua }}</td>
                <td>{{ sumProject.option2Purchase }} {{ currency.ua }}</td>
              </tr>
              <tr v-if="margin
            && ((userData.role === 'manager' && userData.optPriceVisibilityInItems) || userData.role === 'director')">
                <td>{{ staticText.project.titleMargin[actualLang.index] }}</td>
                <td>{{ sumProject.mainMargin }} %</td>
                <td>{{ sumProject.option1Margin }} %</td>
                <td>{{ sumProject.option2Margin }} %</td>
              </tr>
              <tr>
                <td>
                  <span class="price__text">
                    {{ staticText.project.titleTotalProject[actualLang.index] }}
                  </span>
                </td>
                <td>{{ sumProject.mainWithDiscount }} {{ currency.ua }}</td>
                <td>{{ sumProject.option1WithDiscount }} {{ currency.ua }}</td>
                <td>{{ sumProject.option2WithDiscount }} {{ currency.ua }}</td>
              </tr>
            </tbody>
          </table>
          <div class="project__change">
            <button
              v-if="margin
              && ((userData.role === 'manager' && userData.optPriceVisibilityInItems) || userData.role === 'director')"
              type="button"
              class="project__button"
              @click="$store.commit('projects/SET_MARGIN', false)"
            >
              {{ staticText.project.titleButtonWithMargin[actualLang.index] }}
            </button>
            <button
              v-if="!margin
              && ((userData.role === 'manager' && userData.optPriceVisibilityInItems) || userData.role === 'director')"
              type="button"
              class="project__button"
              @click.prevent="statusWarningOpenMargin = true"
            >
              {{
                staticText.project.titleButtonWithoutMargin[actualLang.index]
              }}
            </button>
          </div>
        </div>
        <div class="project__total total">
          <table class="total__table">
            <tbody>
              <tr>
                <td>
                  {{ staticText.project.titleDelivery[actualLang.index] }}
                </td>
                <td>
                  <select
                    v-model="project.delivery_id"
                    class="select select_delivery"
                    :disabled="project.is_order"
                  >
                    <option
                      v-for="delivery in deliveryType"
                      :key="delivery.id"
                      :value="delivery.id"
                      :selected="delivery.id === project.delivery_id"
                    >
                      {{ delivery.name[actualLang.index] }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  {{ staticText.project.titleConnection[actualLang.index] }}
                </td>
                <td>
                  <div class="total__delivery">
                    <input
                      v-model="count"
                      type="text"
                      class="total__input input input_form"
                      @keydown="keyDown"
                      @keyup="keyUpConnection($event)"
                    />
                    <span class="total__text">
                      {{ staticText.project.titleCount[actualLang.index] }}
                    </span>
                    <span class="total__icon"></span>
                    <span class="total__text">
                      {{ PRICE_CONNECTION_PRODUCT }} {{ currency.ua }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ staticText.project.titleTotal[actualLang.index] }}</td>
                <td>
                  {{
                    sumProject.totalWithDiscount +
                      count * PRICE_CONNECTION_PRODUCT
                  }}
                  {{ currency.ua }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
      <form class="project__form form">
        <div v-if="project.is_order" class="form__title">
          {{ staticText.project.titleProjectOrder[actualLang.index] }}
          {{ project.id }}
          <br />
          ({{ staticText.project.titleOrder[actualLang.index] }}
          {{ project.order_id }})
        </div>
        <div v-else class="form__title">
          {{ staticText.project.titleProjectOrder[actualLang.index] }}
          {{ project.id }}
        </div>
        <div class="form__row">
          <span class="form__subtitle">
            {{ staticText.project.titleProjectClientName[actualLang.index] }}
            <span>*</span>
          </span>
          <input
            v-model="projectForm.name"
            type="text"
            class="form__input input"
            :disabled="project.is_order"
          />
        </div>
        <div class="form__row">
          <span class="form__subtitle">
            {{ staticText.project.titleProjectClientPhone[actualLang.index] }}
            <span>*</span>
          </span>
          <TheMask
            v-model="projectForm.phone"
            class="form__input input"
            mask="+38 (0##) ### ## ##"
            type="tel"
            placeholder="+38 (050) 050 50 50"
            required
            :disabled="project.is_order"
          />
        </div>
        <div v-if="project.delivery_id != 4" class="form__row">
          <span class="form__subtitle">
            {{ staticText.project.titleProjectClientEmail[actualLang.index] }}
            <span>*</span>
          </span>
          <input
            v-model="projectForm.email"
            type="text"
            class="form__input input"
            :disabled="project.is_order"
          />
        </div>
        <div v-if="project.is_order" class="form__row form__calendar">
          <span class="form__subtitle">
            {{ staticText.project.titleProjectOrderDate[actualLang.index] }}
            <span>*</span>
          </span>
          <div class="form__date">
            <input
              v-model="project.order.date"
              type="text"
              placeholder="ДД.ММ.ГГГГ"
              class="form__input form__input_date input"
              :readonly="true"
            />
            <button
              type="button"
              class="form__button-date icon-calendar-icon"
            ></button>

            <span v-if="project.order.date" class="message">
              {{ messageProjectOrderDate }}
            </span>
            <v-date-picker
              v-if="showPicker"
              ref="picker"
              v-model="picker"
              :show-current="true"
              :allowed-dates="allowedDatesDelivery"
              :disabled="project.is_order"
              color="#A12C3A"
              locale="ru"
              first-day-of-week="1"
              @input="formatDate"
              @change="closeDatePicker"
            >
            </v-date-picker>
          </div>
          <span class="form__desc">
            {{
              staticProject.descProjectOrderDate(currency.ua)[actualLang.index]
            }}
          </span>
        </div>
        <div class="form__row form__delivery">
          <div class="form__subtitle">
            {{ staticText.project.titleProjectDelivery[actualLang.index] }}
            <span>*</span> /
            <router-link to="/page/delivery" class="link">
              {{
                staticText.project.titleProjectDeliveryLink[actualLang.index]
              }}
            </router-link>
          </div>
          <div class="form__date">
            <input
              :readonly="true"
              :value="
                Number(project.delivery_id) === 1
                  ? staticText.delivery[1][actualLang.index]
                  : Number(project.delivery_id) === 2
                    ? staticText.delivery[2][actualLang.index]
                    : Number(project.delivery_id) === 3
                      ? staticText.delivery[3][actualLang.index]
                      : staticText.delivery[4][actualLang.index]
              "
              type="text"
              class="form__input input"
            />
            <span
              v-if="
                project.delivery_id ===
                  delivery_id('Доставка на склад покупателя') ||
                  (project.delivery_id ===
                    delivery_id('Доставка до складу покупця') &&
                    sumProject.totalWithDiscount > 50000)
              "
              class="message"
            >
              {{ staticText.text.delivery[1][actualLang.index] }}
            </span>
            <span
              v-if="
                project.delivery_id ===
                  delivery_id('Доставка на склад покупателя') ||
                  (project.delivery_id ===
                    delivery_id('Доставка до складу покупця') &&
                    sumProject.totalWithDiscount <= 50000)
              "
              class="message"
            >
              {{ staticText.text.delivery[2][actualLang.index] }} 250 грн
            </span>
            <span
              v-if="project.delivery_id == 4"
              class="message"
            >
              {{ staticText.text.delivery[3][actualLang.index] }}
            </span>
          </div>
        </div>
        <div
          v-if="
            project.delivery_id === delivery_id('Доставка Новой Почтой') ||
              project.delivery_id === delivery_id('Доставка Новою Поштою')
          "
          class="form__row"
        >
          <span class="form__subtitle">
            {{ staticText.project.titleCity[actualLang.index] }}
          </span>
          <input v-model="project.city" type="text" class="form__input input" />
        </div>
        <div
          v-if="
            project.delivery_id === delivery_id('Доставка Новой Почтой') ||
              project.delivery_id === delivery_id('Доставка Новою Поштою')
          "
          class="form__row"
        >
          <span class="form__subtitle">
            {{ staticText.project.titlePostcode[actualLang.index] }}
          </span>
          <input
            v-model="project.postcode"
            type="text"
            class="form__input input"
          />
        </div>

        <div
          v-if="project.delivery_id == 4"
          class="form__row"
        >
          <span class="form__subtitle">
            {{ staticText.project.titleCity[actualLang.index] }}
          </span>
          <v-autocomplete
            v-model="project.delivery_courier.city"
            class="form__input select"
            :items="deliveryCities"
            :label="staticText.project.titleCitySelect[actualLang.index]"
            :hide-details="true"
            :single-line="true"
            :clearable="true"
            :no-filter="true"
            :disabled="project.is_order"
            :no-data-text="staticText.project.titleCityNodata[actualLang.index]"
            @update:search-input="searchCityHandler"
            @click:clear="clearDelivery('city')"
          >
            <template v-slot:item="{item}">
              {{ item.name }}
            </template>
            <template v-slot:selection="{item}">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </div>
        <div
          v-if="project.delivery_id == 4"
          class="form__row"
        >
          <span class="form__subtitle">
            {{ staticText.project.titleStreet[actualLang.index] }}
          </span>
          <v-autocomplete
            v-model="project.delivery_courier.street"
            class="form__input select"
            :items="deliveryStreets"
            :label="staticText.project.titleStreetSelect[actualLang.index]"
            :hide-details="true"
            :single-line="true"
            :clearable="true"
            :no-filter="true"
            :no-data-text="staticText.project.titleStreetNodata[actualLang.index]"
            :disabled="readonly('street') || project.is_order"
            @update:search-input="searchStreetHandler"
            @click:clear="clearDelivery('street')"
          >
          </v-autocomplete>
        </div>
        <div
          v-if="project.delivery_id == 4"
          class="form__row"
        >
          <span class="form__subtitle">
            {{ staticText.project.titleBuilding[actualLang.index] }}
          </span>
          <input
            v-model="project.delivery_courier.building"
            type="text"
            class="form__input input"
            :disabled="readonly('building') || project.is_order"
          />
        </div>
        <div
          v-if="project.delivery_id == 4"
          class="form__row"
        >
          <span class="form__subtitle">
            {{ staticText.project.titleApp[actualLang.index] }}
          </span>
          <input
            v-model="project.delivery_courier.app"
            type="text"
            class="form__input input"
            :disabled="readonly('app') || project.is_order"
          />
        </div>

        <div class="form__row">
          <span class="form__subtitle">
            {{ staticText.project.titleProjectOrderComment[actualLang.index] }}
          </span>
          <textarea
            v-model="project.order.comment"
            class="form__textarea"
            rows="6"
            :disabled="project.is_order"
          ></textarea>
        </div>
        <p class="error-validate" v-if="formErrors.length">
          <b>{{ formErrorMessage }}</b>
        </p>
        <div class="form__row form__row_buttons">
          <button
            type="button"
            :class="{ disable: project.is_order }"
            class="form__button buttons"
            @click.self.stop="dropdownActive = !dropdownActive"
          >
            {{ staticText.project.titleProjectButtonSave[actualLang.index] }}
            <ul
              v-if="!project.is_order"
              class="buttons__dropdown dropdown"
              :class="{ dropdown_active: dropdownActive }"
            >
              <li class="dropdown__item">
                <button
                  type="button"
                  class="form__btn"
                  @click="handlerSaveBtn('project', false, false)"
                >
                  {{
                    staticText.project.titleProjectButtonSaveChanges[
                      actualLang.index
                    ]
                  }}
                </button>
              </li>
              <li class="dropdown__item">
                <button
                  type="button"
                  class="form__btn"
                  @click="handlerSaveBtn('project', true, false)"
                >
                  {{
                    staticText.project.titleProjectButtonSaveNewProject[
                      actualLang.index
                    ]
                  }}
                </button>
              </li>
            </ul>
          </button>
          <button
            type="button"
            :class="{ disable: project.is_order }"
            class="form__button"
            @click="handlerWarningPopup()"
          >
            {{
              staticText.project.titleProjectButtonCheckout[actualLang.index]
            }}
          </button>
        </div>
        <div class="form__row">
          <span class="form__text">
            {{ staticText.project.titleProjectOrderDesc[actualLang.index] }}
          </span>
        </div>
      </form>
    </div>

    <v-dialog v-model="statusWarningOpenMargin" max-width="520px">
      <div class="module__thanks">
        <span
          class="module__enter-client-close"
          @click.prevent="statusWarningOpenMargin = false"
        ></span>
        <div class="module__thanks-inner">
          <div class="module__thanks-title">
            {{ staticText.popups.popupTitle[actualLang.index] }}
          </div>
          <div class="models_add__popup">
            <a @click.stop="handlerCheckMargin">
              {{ staticText.popups.popupTextYes[actualLang.index] }}
            </a>
            <a @click.prevent="statusWarningOpenMargin = false">
              {{ staticText.popups.popupTextNo[actualLang.index] }}
            </a>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="statusWarningSavePopup" max-width="520px">
      <div class="module__thanks">
        <span
          class="module__enter-client-close"
          @click.prevent="statusWarningSavePopup = false"
        ></span>
        <div class="module__thanks-inner">
          <div class="module__thanks-title">
            {{ staticText.project.titleProjectOrderDesc[actualLang.index] }}
          </div>
          <div class="form__row form__calendar">
            <span class="form__subtitle">
              {{ staticText.project.titleProjectOrderDate[actualLang.index] }}
              <span>*</span>
            </span>
            <div class="form__date">
              <input
                v-model="project.order.date"
                type="text"
                placeholder="ДД.ММ.ГГГГ"
                class="form__input form__input_date input"
                :readonly="true"
              />
              <button
                type="button"
                class="form__button-date icon-calendar-icon"
                @click.stop="showPicker = !showPicker"
              ></button>
<!--              <span v-if="project.order.date" class="message in-popup">-->
<!--                {{ messageProjectOrderDate }}-->
<!--              </span>-->
              <v-date-picker
                v-if="showPicker"
                ref="picker"
                v-model="picker"
                :show-current="true"
                :allowed-dates="allowedDatesDelivery"
                :disabled="project.is_order"
                color="#A12C3A"
                locale="ru"
                width="230px"
                prev-icon="‹"
                next-icon="›"
                first-day-of-week="1"
                @input="formatDate"
                @change="closeDatePicker"
              >
              </v-date-picker>
            </div>
            <span class="form__desc in-popup">
              <span v-if="date14">{{ messageProjectOrderDate }}</span>
              <br />
              {{
                staticProject.descProjectOrderDate(currency.ua)[actualLang.index]
              }}
            </span>
            <p class="error-validate" v-if="formErrors.length">
              <b>{{ formErrorMessage }}</b>
            </p>
          </div>
          <div class="models_add__popup">
            <a @click.stop="handlerSaveBtn('project/order', false, true)">
              {{ staticText.btns[10][actualLang.index] }}
            </a>
            <a @click.prevent="statusWarningSavePopup = false">
              {{ staticText.btns[9][actualLang.index] }}
            </a>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-text> </v-card-text>

        <v-card-text>
          {{ popupText }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            {{ staticText.btns[1][actualLang.index] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="safariAlert" width="500" content-class="safari-action">
      <v-card>
        <v-card-text> </v-card-text>

        <v-card-text>
          {{ staticText.project.safariAlertText[actualLang.index] }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn color="primary" text @click="safariAlert = false">-->
<!--            {{ staticText.btns[1][actualLang.index] }}-->
<!--          </v-btn>-->
          <a
            :href="safariLink"
            class="safari-link"
            target="_blank"
            @click="safariAlert = false"
          >
            {{ staticText.project.safariAlertBtnConfirm[actualLang.index] }}
          </a>
          <v-btn color="primary" text @click="safariAlert = false">
            {{ staticText.project.safariAlertBtnCancel[actualLang.index] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { TheMask } from 'vue-the-mask';
import debounce from 'debounce';
import { store } from '../store';
import { staticText } from '../i18n';
import { baseDomain, baseDomainImg } from '../constans';

import CheckBox from '../components/CheckBox.vue';
import RadioButton from '../components/RadioButton.vue';
import ArrowGoBack from '../components/ArrowGoBack.vue';
import Button from '../components/Button.vue';

export default {
  name: 'Project',
  components: {
    Button,
    CheckBox,
    RadioButton,
    ArrowGoBack,
    TheMask,
  },
  data() {
    return {
      backRoutePath: '/profile/projects',
      dialog: false,
      popupText: '',
      PRICE_CONNECTION_PRODUCT: 200,
      count: 0,
      showPicker: false,
      picker: '',
      dropdownActive: false,
      discountError: [],
      radio: {
        name: '',
      },
      projectForm: {
        phone: '',
        email: '',
        name: '',
      },
      staticText,
      formErrors: [],
      formErrorMessage: '',
      statusWarningSavePopup: false,
      statusWarningOpenMargin: false,
      token: JSON.parse(localStorage.alcotecUserInfo).user.token,
      baseDomain,
      baseDomainImg,
      safariAlert: false,
      safariLink: '',
      fio: true,
      dropdownPdfFull: false,
      dropdownPdfMin: false,
      dropdownPdfFullNoPrice: false,
      dropdownPdfMinNoPrice: false,
      deliveryCities: [],
      deliveryStreets: [],
    };
  },
  computed: {
    ...mapState({
      currency: (state) => state.settings.currency,
      project: (state) => state.projects.project,
      staticProject: (state) => state.projects.staticProject,
      userData: (state) => state.users.userData,
      margin: (state) => state.projects.margin,
      complectations: (state) => state.projects.complectations,
      deliveryType: (state) => state.projects.delivery,
      actualLang: (state) => state.settings.actualLang,
      profileInfo: (state) => state.profiles.profileInfo,
    }),
    titlesTable() {
      return this.staticProject
        .titlesTable(this.currency.ua)[this.actualLang.index].filter((title) => {
          if (
            title.toLowerCase().indexOf('маржа') !== -1 ||
            title.toLowerCase().indexOf('закупка') !== -1 ||
            title.toLowerCase().indexOf('закупівля') !== -1
          ) {
            return this.margin;
          } else if (
            title.indexOf('Цена в комплекте') !== -1 ||
            title.indexOf('Ціна в комплекті') !== -1 ||
            title.indexOf('Сумма в комплекте') !== -1 ||
            title.indexOf('Сума в комплекті') !== -1
          ) {
            return this.project.isSetDiscount === true;
          } else {
            return true;
          }
        });
    },
    date14() {
      let date;

      if (this.project.order.date) {
        const array = this.project.order.date.split('.');
        const newDate = [];

        newDate.push(array[1]);
        newDate.push(array[0]);
        newDate.push(array[2]);
        date = newDate.join('.');
      }

      const currentDate = this.picker ? new Date(this.picker) : new Date(date);

      currentDate.setDate(currentDate.getDate() + 14);

      const DD =
        parseInt(currentDate.getDate(), 10) < 10
          ? `0${currentDate.getDate() + 1}`
          : currentDate.getDate() + 1;
      const MM =
        parseInt(currentDate.getMonth(), 10) + 1 < 10
          ? `0${parseInt(currentDate.getMonth(), 10) + 1}`
          : parseInt(currentDate.getMonth(), 10) + 1;
      const YYYY = currentDate.getFullYear();

      if (DD && MM && YYYY) {
        return `${DD - 1}.${MM}.${YYYY}`;
      } else {
        return false;
      }
    },
    equipmentArray() {
      const equipment = {};

      this.complectations.forEach((e) => {
        equipment[e.id] = [];
        this.project.products.forEach((el) => {
          if (parseInt(el.complectation_id, 10) === parseInt(e.id, 10)) {
            equipment[e.id].push(el.id);
          }
        });
        equipment[e.id].sort();
      });

      return equipment;
    },
    sumProject() {
      let complectation = {
        main: 0,
        option1: 0,
        option2: 0,
        total: 0,
        mainWithDiscount: 0,
        option1WithDiscount: 0,
        option2WithDiscount: 0,
        totalWithDiscount: 0,
        mainDiscount: 0,
        option1Discount: 0,
        option2Discount: 0,
        mainPurchase: 0,
        option1Purchase: 0,
        option2Purchase: 0,
        mainMargin: 0,
        option1Margin: 0,
        option2Margin: 0,
      };

      complectation = this.project.products.reduce((prev, product) => {
        if (product.active) {
          // if (product.setIds && product.setIds.length > 1 ) {
          //   console.log(this.testArrayIncludes(product.setIds));
          // }
          const totalProductPrice =
            product.amount * ((product.setPrice && this.testArrayIncludes(product.setIds)) ? product.setPrice : (product.sale ? product.sale : product.price));
          const totalProductPriceWithDiscount = this.calculateTotalPrice(product);

          this.calculateProductMargin(product);

          const comp =
            this.complectations.find(
              (c) => +c.id === +product.complectation_id,
            ) || {};

          switch (comp.name && comp.name[this.actualLang.index]) {
            case 'Вариант 1':
            case 'Варіант 1':
              prev.option1 += totalProductPrice;
              prev.option1WithDiscount += totalProductPriceWithDiscount;
              prev.option1Purchase += product.purchase * product.amount;
              break;
            case 'Вариант 2':
            case 'Варіант 2':
              prev.option2 += totalProductPrice;
              prev.option2WithDiscount += totalProductPriceWithDiscount;
              prev.option2Purchase += product.purchase * product.amount;
              break;
            default:
              prev.main += totalProductPrice;
              prev.mainWithDiscount += totalProductPriceWithDiscount;
              prev.mainPurchase += product.purchase * product.amount;
              break;
          }
        }

        return prev;
      }, complectation);

      complectation.option1 = complectation.main + complectation.option1;
      complectation.option2 = complectation.main + complectation.option2;

      complectation.option1WithDiscount = Math.round(
        complectation.mainWithDiscount + complectation.option1WithDiscount,
      );
      complectation.option2WithDiscount = Math.round(
        complectation.mainWithDiscount + complectation.option2WithDiscount,
      );
      complectation.mainWithDiscount = Math.round(
        complectation.mainWithDiscount,
      );

      complectation.option1Purchase =
        complectation.mainPurchase + complectation.option1Purchase;
      complectation.option2Purchase =
        complectation.mainPurchase + complectation.option2Purchase;

      switch (this.radio.name) {
        case 'option1':
          complectation.total = complectation.option1;
          complectation.totalWithDiscount = complectation.option1WithDiscount;
          this.project.complectation_id = 2;
          break;
        case 'option2':
          complectation.total = complectation.option2;
          complectation.totalWithDiscount = complectation.option2WithDiscount;
          this.project.complectation_id = 3;
          break;
        default:
          complectation.total = complectation.main;
          complectation.totalWithDiscount = complectation.mainWithDiscount;
          this.project.complectation_id = 1;
          break;
      }

      // Расчет общей скидки
      complectation.mainDiscount =
        complectation.main === 0
          ? 0
          : (
            ((complectation.main - complectation.mainWithDiscount) * 100) /
            complectation.main
          ).toFixed(1);
      complectation.option1Discount =
        complectation.option1 === 0
          ? 0
          : (
            ((complectation.option1 - complectation.option1WithDiscount) *
              100) /
            complectation.option1
          ).toFixed(1);
      complectation.option2Discount =
        complectation.option2 === 0
          ? 0
          : (
            ((complectation.option2 - complectation.option2WithDiscount) *
              100) /
            complectation.option2
          ).toFixed(1);

      // Расчет общей маржи
      complectation.mainMargin =
        complectation.mainWithDiscount === 0
          ? 0
          : (
            ((complectation.mainWithDiscount - complectation.mainPurchase) /
              complectation.mainWithDiscount) *
            100
          ).toFixed(2);
      complectation.option1Margin =
        complectation.option1WithDiscount === 0
          ? 0
          : (
            ((complectation.option1WithDiscount -
              complectation.option1Purchase) /
              complectation.option1WithDiscount) *
            100
          ).toFixed(2);
      complectation.option2Margin =
        complectation.option2WithDiscount === 0
          ? 0
          : (
            ((complectation.option2WithDiscount -
              complectation.option2Purchase) /
              complectation.option2WithDiscount) *
            100
          ).toFixed(2);

      return complectation;
    },
    messageProjectOrderDate() {
      return this.staticProject.messageProjectOrderDate(
        this.date14,
        this.project.order.date,
      )[this.actualLang.index];
    },
    projectHasSets() {
      return this.project.isSetDiscount;
    },
    projectDeliveryId() {
      return this.project.delivery_id;
    },
  },
  watch: {
    actualLang: {
      handler() {
        let order = false;

        if (this.project && this.project.is_order) {
          order = true;
        }

        this.validationForm(order);
      },
    },
    projectDeliveryId: {
      handler(val) {
        if (val == 4) {
          this.projectForm.phone = '';
          this.projectForm.email = '';
          this.projectForm.name = '';
        } else {
          if (this.projectForm.phone === '') this.projectForm.phone = this.profileInfo.phone;
          if (this.projectForm.email === '') this.projectForm.email = this.profileInfo.email;
          if (this.projectForm.name === '') this.projectForm.name = this.profileInfo.partner;
        }
      },
    },
  },
  created() {
    this.$store.dispatch('projects/getComplectations');
    this.$store.dispatch('projects/getDelivery');
    this.$store.commit('profiles/CLOSE_SIDEBAR');

    this.$store.dispatch('profiles/getInfoProfile', {
      cb: () => {
        if (this.projectForm.phone === '') this.projectForm.phone = this.profileInfo.phone;
        if (this.projectForm.email === '') this.projectForm.email = this.profileInfo.email;
        if (this.projectForm.name === '') this.projectForm.name = this.profileInfo.partner;
      },
    });

    if (this.project.complectation_id == 1) {
      this.radio.name = 'main';
    } else if (this.project.complectation_id == 2) {
      this.radio.name = 'option1';
    } else if (this.project.complectation_id == 3) {
      this.radio.name = 'option2';
    }

    if (this.project.client.phone && this.project.client.phone !== '') {
      this.projectForm.phone = this.project.client.phone;
    }
    if (this.project.client.email && this.project.client.email !== '') {
      this.projectForm.email = this.project.client.email;
    }
    if (this.project.client.name && this.project.client.name !== '') {
      this.projectForm.name = this.project.client.name;
    }
    if (this.project.delivery_courier.city
        && this.project.delivery_courier.city.name !== ''
        && this.project.delivery_courier.city.ref !== ''
        && this.deliveryCities.length === 0) {
      this.deliveryCities.push(this.project.delivery_courier.city);
    }
    if (this.project.delivery_courier.street && this.deliveryStreets.length === 0) {
      this.deliveryStreets.push(this.project.delivery_courier.street);
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  destroyed() {
    document.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    readonly(type) {
      let readonly = true;

      if (type === 'street') {
        readonly = (!(this.project.delivery_courier.city
            && this.project.delivery_courier.city.ref
            && this.project.delivery_courier.city.ref !== '')
        );
      }

      if (type === 'building') {
        readonly = (!this.project.delivery_courier.street);
      }
      if (type === 'app') {
        readonly = (!this.project.delivery_courier.building);
      }

      return readonly;
    },
    clearDelivery(target) {
      if (target && target === 'city') {
        this.deliveryCities = [];
        this.deliveryStreets = [];
        this.project.delivery_courier.street = '';
        this.project.delivery_courier.building = '';
        this.project.delivery_courier.app = '';
      } else if (target && target === 'street') {
        this.deliveryStreets = [];
        this.project.delivery_courier.building = '';
        this.project.delivery_courier.app = '';
      }
    },
    searchStreetHandler(search) {
      this.searchStreet(search, this);
    },
    searchStreet: debounce((str, vm) => {
      if (str && str !== '' && str.length > 1
          && vm.project.delivery_courier.city
          && vm.project.delivery_courier.city.ref) {
        vm.$store.dispatch('projects/searchObjectNP', {
          ref: vm.project.delivery_courier.city.ref,
          str: str,
          type: 'street',
          cb: (data) => {
            vm.deliveryStreets = data;
          },
        });
      }
    }, 500),
    searchCityHandler(search) {
      this.searchCity(search, this);
    },
    searchCity: debounce((str, vm) => {
      if (str && str !== '' && str.length > 1) {
        vm.$store.dispatch('projects/searchObjectNP', {
          ref: null,
          str: str,
          type: 'city',
          cb: (data) => {
            vm.deliveryCities = data;
          },
        });
      }
    }, 500),
    testArrayIncludes(arr1) {
      if (this.equipmentArray['1'] && Array.isArray(this.equipmentArray['1'])) {
        const complect1 = this.equipmentArray['1'].concat(this.equipmentArray['2']);
        const complect2 = this.equipmentArray['1'].concat(this.equipmentArray['3']);

        return !!(arr1.every((elem) => complect1.includes(elem)) || arr1.every((elem) => complect2.includes(elem)));
      } else {
        return false;
      }
    },
    handlerCheckMargin() {
      this.$store.commit('projects/SET_MARGIN', true);
      this.statusWarningOpenMargin = false;
    },
    routeSpecifications(path) {
      this.$store.dispatch('profiles/getInfoProfile', {
        cb: () => {
          const newToken =
            localStorage.alcotecUserInfo &&
            JSON.parse(localStorage.alcotecUserInfo).user.token;

          const lang =
              localStorage.alcotecActualLang &&
              JSON.parse(localStorage.alcotecActualLang).index;

          const pathWithToken = `${path}lang=${lang}&auth-token=${newToken}`;

          if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            this.safariLink = pathWithToken;
            this.safariAlert = true;
          } else {
            window.open(pathWithToken, '_blank');
          }
        },
      });
    },
    allowedDatesDelivery(val) {
      const mounth =
        parseInt(val.split('-')[1], 10) >= new Date().getMonth() + 1;
      const nextMounth =
        parseInt(val.split('-')[1], 10) > new Date().getMonth() + 1;
      const yearNext = parseInt(val.split('-')[0], 10) > new Date().getFullYear();
      const days = parseInt(val.split('-')[2], 10) >= new Date().getDate();

      if (yearNext) {
        return true;
      } else if (!mounth) {
        return false;
      } else if (mounth && nextMounth) {
        return true;
      } else {
        return days;
      }
    },
    handlerWarningPopup(saveAsOrder) {
      if (this.project && this.project.is_order) {
        this.popupText = {
          ru: 'Вы не можете редактировать данный заказ',
          ua: 'Ви не можете редагувати дане замовлення',
        }[this.actualLang.index];
        this.dialog = true;

        return;
      } else if (!this.checkError() && this.validationForm(saveAsOrder)) {
        this.statusWarningSavePopup = !this.statusWarningSavePopup;
      } else {
        this.popupText = {
          ru: 'Недопустимые параметры в проэкте',
          ua: 'Неприпустимі параметри в проекті',
        }[this.actualLang.index];
        this.dialog = true;
      }
    },
    validationForm(saveAsOrder) {
      this.formErrors = [];

      if (!this.projectForm.phone) {
        this.formErrors.push('phone');
      }
      if (!this.projectForm.name) {
        this.formErrors.push('name');
      }

      if (saveAsOrder && !this.project.order.date) {
        this.formErrors.push('date');
      }

      if (this.project.delivery_id != 4 && !this.projectForm.email) {
        this.formErrors.push('email');
      }

      if (this.formErrors.length && this.formErrors.length > 1) {
        this.formErrorMessage = this.staticText.project.formValidationErrors.all[this.actualLang.index];

        return false;
      } else if (this.formErrors.length && this.formErrors.length === 1) {
        if (this.formErrors[0] === 'phone') {
          this.formErrorMessage = this.staticText.project.formValidationErrors.phone[this.actualLang.index];
        } else if (this.formErrors[0] === 'name') {
          this.formErrorMessage = this.staticText.project.formValidationErrors.name[this.actualLang.index];
        } else if (this.formErrors[0] === 'email') {
          this.formErrorMessage = this.staticText.project.formValidationErrors.email[this.actualLang.index];
        } else if (this.formErrors[0] === 'date') {
          this.formErrorMessage = this.staticText.project.formValidationErrors.date[this.actualLang.index];
        }

        return false;
      } else {
        return true;
      }
    },
    closeDatePicker() {
      this.showPicker = false;
    },
    delivery_id(name) {
      const delivery = this.deliveryType.find(
        (d) => d.name[this.actualLang.index] === name,
      );

      return delivery && delivery.id;
    },
    simpleParseProject() {
      const parseProducts = this.project.products.map((e) => {
        return {
          id: e.id,
          priceUAH: e.price,
          sale: e.sale ? e.sale : 0,
          count: e.amount,
          discount: e.discount,
          complectation_id: e.complectation_id,
          purchase: e.purchase,
        };
      });

      let withOutSomeField;

      withOutSomeField = ({ products, ...rest }) => rest;

      const parseProject = withOutSomeField(this.project);

      parseProject.products = parseProducts;

      delete parseProject.client;
      delete parseProject.order; // date, comment

      return parseProject;
    },
    parseProject(deleteId = false) {
      const parseProducts = this.project.products.map((e) => {
        return {
          id: e.id,
          priceUAH: e.price,
          sale: e.sale ? e.sale : 0,
          count: e.amount,
          discount: e.discount,
          complectation_id: e.complectation_id,
          purchase: e.purchase,
        };
      });

      let withOutSomeField;

      if (deleteId) {
        withOutSomeField = ({ products, id, ...rest }) => rest;
      } else {
        withOutSomeField = ({ products, ...rest }) => rest;
      }

      const parseProject = withOutSomeField(this.project);

      parseProject.products = parseProducts;

      if (!this.projectForm.phone.includes('+')) {
        const slicePhone = `+380${this.projectForm.phone}`;

        this.projectForm.phone = slicePhone;
      }

      parseProject.client = this.projectForm;

      return parseProject;
    },
    getPdfHandler(urlForProject, urlForPdf, save) {
      if (save) {
        this.simpleSaveProject(urlForProject, () => {
          this.routeSpecifications(urlForPdf);
          this.$store.commit('settings/SET_LOADER_STATUS', false);
        });
      } else {
        this.routeSpecifications(urlForPdf);
      }
    },
    simpleSaveProject(url, callback) {
      if (this.project && this.project.is_order) {
        callback();
      } else {
        this.project.price =
          this.sumProject.totalWithDiscount +
          this.count * this.PRICE_CONNECTION_PRODUCT;
        const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

        this.$store.commit('settings/SET_LOADER_STATUS', true);
        this.$store.dispatch('projects/sendingDataProject', {
          url,
          token,
          project: this.simpleParseProject(),
          cb: (data) => {
            if (data.success) {
              callback();
            } else if (data.message) {
              this.popupText = {
                ru: data.message.ru,
                ua: data.message.ua,
              }[this.actualLang.index];
              this.dialog = true;
              this.$store.commit('settings/SET_LOADER_STATUS', false);
            } else {
              this.popupText = {
                ru: 'Неизвестная ошибка',
                ua: 'Невідома помилка',
              }[this.actualLang.index];
              this.dialog = true;
              this.$store.commit('settings/SET_LOADER_STATUS', false);
            }
          },
        });
      }
    },
    handlerSaveBtn(url, deleteId = false, saveAsOrder = false) {
      if (this.project && this.project.is_order) {
        this.popupText = {
          ru: 'Вы не можете редактировать данный заказ',
          ua: 'Ви не можете редагувати дане замовлення',
        }[this.actualLang.index];
        this.dialog = true;

        return;
      } else if (!this.checkError() && this.validationForm(saveAsOrder)) {
        this.project.price =
          this.sumProject.totalWithDiscount +
          this.count * this.PRICE_CONNECTION_PRODUCT;
        this.$store.commit('basket/SET_POPUP_IS_LOADING');
        const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

        this.$store.dispatch('projects/sendingDataProject', {
          url,
          token,
          project: this.parseProject(deleteId),
          cb: (data) => {
            if (data.success) {
              this.popupText = data.message[this.actualLang.index];
              this.$router.push('/profile/projects');
              this.$store.commit('basket/SET_POPUP_IS_LOADING_FALSE');
            } else {
              this.popupText = data.message[this.actualLang.index];
              this.$store.commit('basket/SET_POPUP_IS_LOADING_FALSE');
              this.dialog = true;
            }
          },
        });
      } else {
        this.popupText = {
          ru: 'Недопустимые параметры в проэкте',
          ua: 'Неприпустимі параметри в проекті',
        }[this.actualLang.index];
        this.dialog = true;
      }
    },
    checkError() {
      let error = false;

      this.discountError.forEach((e) => {
        if (e) {
          error = true;
        }
      });

      return error;
    },
    formatDate(value) {
      const [YYYY, MM, DD] = value.split('-');

      this.project.order.date = `${DD}.${MM}.${YYYY}`;
    },
    calculateTotalPrice(product) {
      // eslint-disable-next-line no-restricted-globals
      const productDiscount = isNaN(parseFloat(product.discount))
        ? 0
        : parseFloat(product.discount);

      const productPrice =
        ((product.setPrice && this.testArrayIncludes(product.setIds)) ? product.setPrice : (product.sale ? product.sale : product.price)) -
        (((product.setPrice && this.testArrayIncludes(product.setIds)) ? product.setPrice : (product.sale ? product.sale : product.price)) * productDiscount) / 100;

      return product.amount * productPrice;
    },
    calculateTotalPriceSet(product) {
      // eslint-disable-next-line no-restricted-globals
      const productDiscount = isNaN(parseFloat(product.discount))
        ? 0
        : parseFloat(product.discount);

      const productPrice = product.setPrice - (product.setPrice * productDiscount) / 100;

      return product.amount * productPrice;
    },
    calculateTotalPriceSale(product) {
      // eslint-disable-next-line no-restricted-globals
      const productDiscount = isNaN(parseFloat(product.discount))
        ? 0
        : parseFloat(product.discount);

      const productPrice =
        (product.sale ? product.sale : product.price) -
        ((product.sale ? product.sale : product.price) * productDiscount) / 100;

      return product.amount * productPrice;
    },
    deleteProduct(id) {
      store.commit('projects/DELETE_PRODUCT', id);
    },
    closeDropdown(e) {
      this.dropdownActive = false;
      this.dropdownPdfFull = false;
      this.dropdownPdfMin = false;
      this.dropdownPdfFullNoPrice = false;
      this.dropdownPdfMinNoPrice = false;
      if (
        this.$refs.picker &&
        e.target.closest('.v-picker') !== this.$refs.picker.$el
      ) {
        this.showPicker = false;
      }
    },
    filterInput(e, regexp) {
      if (e.target.tagName === 'INPUT') {
        const code = e.keyCode;

        if (code < 32 || e.ctrlKey || e.altKey || code === 190 || code === 46) return true;
        const char = String.fromCharCode(
          code >= 96 && code <= 105 ? code - 48 : code,
        );

        if (!regexp.test(char)) return false;
      }

      return true;
    },
    keyDown(e) {
      if (e.which != 46 && e.which != 8 && e.which != 37 && e.which != 39) {
        if (!this.filterInput(e, /\d/)) {
          e.preventDefault();
        }
      }
    },
    keyUp(e, i) {
      if (this.project.products[i].discount && (Number.parseFloat(this.project.products[i].discount) > 99.99)) {
        this.project.products[i].discount = this.project.products[i].discount.slice(0, -1);
      }
      const productMargin = Number.parseFloat(this.calculateProductMargin(this.project.products[i]));

      if (productMargin >= parseFloat(this.profileInfo.minMargin) || e.target.value === 0) {
        this.discountError[i] = false;
        this.discountError.reverse().reverse();
      } else {
        this.discountError[i] = true;
        this.discountError.reverse().reverse();
      }
    },
    calculateProductMargin(product) {
      const productDiscount = isNaN(parseFloat(product.discount))
        ? 0
        : parseFloat(product.discount);

      const priceWithDiscount = (((product.setPrice && this.testArrayIncludes(product.setIds)) ? product.setPrice : (product.sale ? product.sale : product.price))
        * (100 - productDiscount)) / 100;
      const margin = ((priceWithDiscount - product.purchase) / priceWithDiscount)
        * 100;

      return margin.toFixed(2);
    },
    focusDiscount(e) {
      if (e.target.value == 0) {
        e.target.value = '';
      }
    },
    keyUpConnection(e) {
      e.target.value = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
      this.count =
        e.target.value > this.project.products.length
          ? this.project.products.length
          : e.target.value;
    },
    handlerAddProduct(project) {
      this.$store.commit('basket/SET_BASKET_PROJECT', project);
      this.$store.commit('basket/SET_TOTAL_PRICE', project);
      this.$router.push('/profile/configurator');
    },
    dropdownPdf(type) {
      if (type === 'full') {
        this.dropdownPdfFull = !this.dropdownPdfFull;
        this.dropdownPdfMin = false;
        this.dropdownPdfFullNoPrice = false;
        this.dropdownPdfMinNoPrice = false;
      } else if (type === 'min') {
        this.dropdownPdfFull = false;
        this.dropdownPdfMin = !this.dropdownPdfMin;
        this.dropdownPdfFullNoPrice = false;
        this.dropdownPdfMinNoPrice = false;
      } else if (type === 'full_no') {
        this.dropdownPdfFull = false;
        this.dropdownPdfMin = false;
        this.dropdownPdfFullNoPrice = !this.dropdownPdfFullNoPrice;
        this.dropdownPdfMinNoPrice = false;
      } else if (type === 'min_no') {
        this.dropdownPdfFull = false;
        this.dropdownPdfMin = false;
        this.dropdownPdfFullNoPrice = false;
        this.dropdownPdfMinNoPrice = !this.dropdownPdfMinNoPrice;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('projects/getProject', {
      id: to.params.id,
      cb: (status) => {
        if (status) {
          next((vm) => {
            if (from.path === '/profile/orders') {
              vm.backRoutePath = from.path;
            }
          });
        } else {
          next('/404-error');
        }
      },
    });
  },
};
</script>
<style lang="scss" scoped>
.line-through {
  text-decoration: line-through;
  color: #9b9b9b;
}
.models_add__popup {
  display: flex;
  justify-content: space-around;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  & a {
    transition: all 0.4s;
    font-family: 'MagistralC';
    font-weight: bold;
    text-align: center;
    max-width: 140px;
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 15px;
    color: #a12c3a;
    background-color: transparent;
    border: 1px solid #a12c3a;
    padding: 12px 20px 10px 20px;
    margin-right: 7.5px;

    &:hover {
      background-color: $black;
      color: $white;
      border: 1px solid $black;
    }
  }
  & a:last-child {
    margin-left: 7.5px;
    margin-right: 0px;
  }
}
.error-validate {
  color: red;
  font-family: 'MagistralC';
  font-weight: bold;
  font-size: 17px;
  padding-bottom: 20px;
  text-align: center;
}
.section {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 88px;
  display: flex;
  justify-content: center;
}
.project {
  &__title {
    font-family: 'MagistralC';
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: $black;
  }
  &__header {
    margin-bottom: 40px;
  }
  &__comments {
    margin-bottom: 40px;
  }
  &__wrapper {
    position: relative;
    margin-bottom: 46px;
    overflow-x: auto;
    scrollbar-color: #262626 #fff;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      background-color: #fff;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #262626;
    }
    & .project__table {
      max-width: 100%;
      width: 100%;
    }
  }
  &__add {
    margin-bottom: 36px;
    padding-left: 6px;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  &__button {
    border: 1px solid $dark_gray;
    border-radius: 4px;
    padding: 12px 24px;
    font-family: 'MagistralC';
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #262626;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: #262626;
    }
  }
  &__price {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 32px;
    margin-bottom: 22px;
  }
  &__change {
    display: flex;
    justify-content: flex-end;
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 48px;
  }
}
.product {
  &.out-of-stock{
    background: #c4c4c4;
  }
  &__name {
    display: inline-flex;
    align-items: center;
    & .product__text{
      display: inline-block;
    }
  }
  &__preview {
    width: 80px;
    height: 75px;
    max-width: 80px;
    max-height: 75px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__img {
    max-width: 80px;
    max-height: 75px;
  }
  &__text {
    max-height: 75px;
    align-self: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: unset;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  &__button {
    width: 24px;
    height: 24px;
    border: 1px solid $black;
    position: relative;
    margin-left: 14px;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 10px;
      background-color: $black;
      transform: translate(-50%, -50%);
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 10px;
      width: 2px;
      background-color: $black;
      transform: translate(-50%, -50%);
    }
    &_dec {
      margin-left: 0;
      margin-right: 14px;
      &:after {
        content: none;
      }
    }
    &:hover {
      background-color: $dark_gray;
    }
  }
  &__amount {
    display: inline-flex;
    align-items: center;
  }
  &__input {
    width: 63px;
    caret-color: #dcc488;
    &:focus {
      border: 1px solid #dcc488;
    }
  }
  &__delete {
    margin-left: 18px;
    cursor: pointer;
    display: block;
    border: none;
    outline: none;
    background-color: transparent;
    flex-shrink: 0;
    position: relative;
    width: 14px;
    height: 14px;
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: #9b9b9b;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #9b9b9b;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:hover,
    &:focus {
      &:after {
        width: 3px;
      }
      &:before {
        height: 3px;
      }
    }
  }
  &__complectation {
    display: flex;
    align-items: center;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__left {
    padding-right: 15px;
    display: flex;
  }
}
.specifications {
  display: flex;
  align-items: center;
  &__title {
    margin-right: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $dark_gray;
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
    &::v-deep .checkbox__text{
      font-family: 'MagistralC';
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #1d1d1d;
      font-weight: bold;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    font-family: 'MagistralC';
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #1d1d1d;
    font-weight: bold;
    transition: all 0.3s;
    position: relative;
    &:hover {
      color: #A12C3A;
    }
    & .dropdown{
      padding: 5px;
      width: auto;
      //min-width: 180px;
      &__item{
        font-size: 12px;
        text-align: center;
        margin-bottom: 10px;
        & .form__btn{
          font-size: 12px;
          line-height: 14px;
          text-align: center;
        }
        &:last-child {
          text-align: center;
        }
      }
    }
  }
  &__img {
    margin-right: 10px;
  }
}
.input {
  border: 1px solid $dark_gray;
  border-radius: 4px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $black;
  padding: 17px;
  &_form {
    font-size: 14px;
    line-height: 19px;
    padding: 8px;
  }
}
.comments {
  display: flex;
  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    color: $dark_gray;
    margin-bottom: 8px;
    padding-left: 17px;
  }
  &__label {
    position: relative;
  }
  &__input {
    padding-right: 54px;
    max-width: 380px;
    width: 100%;
    caret-color: #dcc488;
    &:focus {
      border: 1px solid #dcc488;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
  }
}
.table {
  border-collapse: collapse;
  color: $black;
  &__head {
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  }
  th {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: $black;
    padding: 25px 15px;
  }
  td {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 25px 0;
    &:last-child {
      position: relative;
    }
    &:nth-child(2) {
      text-align: left;
      max-width: 160px;
    }
  }
}
.price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &__title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  &__text {
    text-transform: uppercase;
  }
  &__discount {
    display: inline-flex;
    align-items: center;
  }
  &__button {
    border: 1px solid $dark_gray;
    border-radius: 4px;
    padding: 12px 24px;
    font-family: 'MagistralC';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #fff;
    color: $dark_gray;
    font-weight: bold;
    &:hover {
      background-color: $dark_gray;
      color: #fff;
    }
  }
  &__input {
    width: 100px;
    margin-right: 10px;
    caret-color: #dcc488;
    &:focus {
      border: 1px solid #dcc488;
    }
  }
  &__table {
    max-width: 700px;
    width: 100%;
    border-collapse: collapse;
    color: $black;
    margin-bottom: 22px;
    th {
      padding-top: 10px;
      padding-bottom: 10px;
      &:nth-child(2) {
        padding-left: 40px;
      }
    }
    td {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      text-align: left;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      &:first-child {
        max-width: 290px;
        text-align: right;
      }
      &:nth-child(2) {
        padding-left: 40px;
        max-width: 160px;
      }
      &:nth-child(3) {
        max-width: 120px;
      }
      &:nth-child(4) {
        max-width: 100px;
        & input {
          margin-right: 0px;
        }
      }
    }
  }
}
.total {
  &__delivery {
    display: inline-flex;
    align-items: center;
  }
  &__text {
    white-space: nowrap;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__icon {
    display: block;
    border: none;
    outline: none;
    background-color: transparent;
    flex-shrink: 0;
    position: relative;
    width: 9px;
    height: 9px;
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: $dark_gray;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $dark_gray;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    margin-right: 10px;
  }
  &__input {
    width: 63px;
    margin-right: 8px;
    caret-color: #dcc488;
    &:focus {
      border: 1px solid #dcc488;
    }
  }
  &__table {
    border-collapse: collapse;
    color: $black;
    td {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      text-align: left;
      padding-left: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      &:first-child {
        text-align: right;
      }
    }
  }
}
.select {
  cursor: pointer;
  width: 100%;
  max-width: 118px;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  padding: 8px;
  margin: 0;
  margin-right: 10px;
  border: 1px solid $dark_gray;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../assets/img/projects/arrow-drop-down-24px.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.1em top 50%, 0 0;
  background-color: transparent;
  &_delivery {
    width: 100%;
    max-width: 258px;
  }
}
.add {
  display: flex;
  .add__link {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $main !important;
    font-family: 'MagistralC';
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.16em;
    font-weight: bold;
  }
  &__icon {
    width: 14px;
    height: 14px;
    position: relative;
    margin-right: 29px;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 2px;
      width: 14px;
      background-color: $main;
      transform: translateY(-50%);
    }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      height: 14px;
      width: 2px;
      background-color: $main;
      transform: translateX(-50%);
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  max-width: 520px;
  width: 100%;
  &__title {
    font-family: 'MagistralC';
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: $black;
    margin-bottom: 32px;
  }
  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
      flex-direction: row;
    }
    &_buttons {
      flex-direction: row;
      align-items: center;
    }
  }
  &__text {
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    color: $dark_gray;
    padding-left: 17px;
    margin-bottom: 6px;
  }
  &__desc {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $dark_gray;
    padding-left: 17px;
    margin-top: 8px;
  }
  &__date {
    position: relative;
  }
  &__input {
    width: 100%;
    caret-color: #dcc488;
    &:focus {
      border: 1px solid #dcc488;
    }
    &_date {
      padding-right: 36px;
    }
    &.select{
      max-width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #1D1D1D;
      padding: 12px;
      &::v-deep .v-input__control{
        & .v-input__slot:before{
          display: none;
        }
        & .v-input__slot{
          &:after{
            display: none;
          }
        }
      }
      &::v-deep .v-select__slot{
        &:after{
          display: none;
        }
      }
      &::v-deep .v-input__icon--clear{
        & button.v-icon{
          width: 16px;
          height: 16px;
          &:after{
            content: '';
            display: block;
            width: 2px;
            height: 100%;
            background-color: #9b9b9b;
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 1;
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:before{
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: #9b9b9b;
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 1;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
  &__button {
    border: 1px solid $main;
    border-radius: 4px;
    padding: 12px 24px;
    font-family: 'MagistralC';
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #fff;
    color: $main;
    font-weight: bold;
    &:first-child {
      margin-right: 40px;
      display: flex;
      align-items: center;
      &:after {
        content: '\e92e';
        font-family: 'icomoon';
        display: block;
        font-size: 10px;
        margin-left: 30px;
        margin-bottom: 3px;
        width: 10px;
        height: 10px;
        transition: all 0.2s;
      }
      &:hover:after {
        color: $white;
      }
      &:active {
        color: $white;
      }
    }
    &:last-child:hover {
      background-color: $main;
      color: #fff;
    }

    transition: all 0.3s;
    &:hover {
      background-color: $main;
      color: $white;
    }
    &:active {
      background-color: $main;
      color: $white;
    }
  }
  &__btn {
    font-family: 'MagistralC';
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $main;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  &__button-date {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
  }
  &__textarea {
    border: 1px solid $dark_gray;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    padding: 17px;
    resize: none;
    caret-color: #dcc488;
    &:focus {
      border: 1px solid #dcc488;
    }
  }
}
::v-deep .v-list-item--link:before{
  background: none;
}
.buttons {
  position: relative;
  .dropdown {
    padding-left: 24px;
    box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.75);
  }
}
.dropdown {
  max-width: 308px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  position: absolute;
  background-color: #fff;
  top: calc(100% + 2px);
  left: -9999px;
  border-radius: 4px;
  z-index: 10;
  &__item {
    text-align: left;
    margin-bottom: 24px;
    &:last-child {
      text-align: left;
      margin-bottom: 0;
    }
  }
  &_active {
    left: 0;
  }
}
.message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  white-space: nowrap;
  background-color: #000;
  top: 50%;
  transform: translateY(-50%);
  left: 108%;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  display: block;
  &:after {
    content: '';
    display: block;
    border-top: 11px solid transparent;
    border-right: 11px solid #000;
    border-bottom: 11px solid transparent;
    border-left: 0px solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    right: 100%;
    top: 50%;
    margin-top: -11px;
  }
}

.icon-pdf-icon {
  font-size: 18px;
}
.icon-icon-pencil {
  font-size: 20px;
}
.icon-calendar-icon {
  font-size: 22px;
}
.discount {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &__error {
    color: $red;
    position: absolute;
    top: 100%;
    left: -9999px;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  &_error {
    .input {
      border-color: $red;
      color: $red;
    }
    .discount__error {
      left: 50%;
    }
  }
  &.disabled {
    .input{
      background-color: #e5e5e5;
    }
  }
}
.v-picker {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translateY(-50%) translateX(-50%);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
}
::v-deep .v-date-picker-header{
  padding: 4px 4px;
}

::v-deep .v-btn--icon{
  &[aria-label^=Next]{
    & .v-btn__content{
      & .v-icon{
        padding-left: 4px;
      }
    }
  }
  &[aria-label^=Previous]{
    & .v-btn__content{
      & .v-icon{
        padding-right: 1px;
      }
    }
  }
  & .v-btn__content{
    & .v-icon{
      border: 1px solid #808080;
      border-radius: 50%;
      display: inline-block;
      color: #000;
      width: 24px;
      height: 24px;
      line-height: 10px;
    }
  }
}
.form__row_buttons {
  justify-content: space-between;
}
.form__subtitle span {
  color: red;
}
.form__subtitle .link {
  color: $main;
}

.module__thanks-title {
  font-size: 18px;
  line-height: 20px;
}

.product__name {
  color: black;
}

.form__button.disable {
  border: 1px solid #fff;
  color: #fff;
  background-color: #c4c4c4;
  cursor: not-allowed;
  &:hover {
    border: 1px solid #fff;
    color: #fff;
    background-color: #c4c4c4;
  }
}

.safari-action{
  .v-card__actions{
    justify-content: space-evenly;
    .safari-link{
      border: 1px solid $main;
      border-radius: 4px;
      padding: 12px 24px;
      font-family: 'MagistralC';
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 2px;
      text-transform: uppercase;
      background-color: #fff;
      color: $main;
      font-weight: bold;
      transition: all 0.3s;
      &:hover {
        background-color: $main;
        color: $white;
      }
    }
  }
}
.form__desc {
  &.in-popup {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1399px) {
  .header__right {
    margin-top: 20px;
  }
}
@media (max-width: 1100px) {
  .message {
    white-space: normal;
    left: 8px;
    top: 85px;
    &:after {
      display: none;
    }
  }
  .form__calendar {
    margin-bottom: 57px;
    .message {
      top: 110px;
    }
  }
  .form__delivery {
    margin-bottom: 57px;
  }
}

@media (max-width: 920px) {
  .header__right {
    margin-left: auto;
  }
  .specifications__list {
    display: block;
  }
  .project__comments {
    justify-content: center;
  }
  .project__add {
    justify-content: center;
  }
  .project__total {
    justify-content: flex-start;
  }
  .price__table,
  .project__change {
    margin: 0 auto;
  }
  .price__table {
    max-width: 700px;
    width: 100%;
  }
  .total__table {
    margin: 0 auto;
  }
  .project__form {
    max-width: none;
  }
  .form__row_buttons {
    justify-content: space-between;
  }
  .form__desc {
    &.in-popup {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media (max-width: 604px) {
  .project__comments {
    flex-direction: column;
  }
  .comments__item {
    display: block;
    margin-right: 0px;
    &:first-child {
      margin-bottom: 20px;
    }
  }
  .comments__input {
    max-width: none;
    padding-right: 0px;
  }
}
@media (max-width: 520px) {
  .project__title {
    font-size: 24px;
  }
  .module__thanks .module__thanks-inner .module__thanks-title {
    font-size: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 20px;
  }
  .models_add__popup a {
    padding: 10px 12px 10px 12px;
    margin-right: 7.5px;
    height: 37px;
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  .form__calendar {
    margin-bottom: 75px;
    .message {
      top: 120px;
    }
  }
}
@media (max-width: 470px) {
  .form__row_buttons {
    flex-wrap: wrap;
    justify-content: center;
  }
  .form__button:first-child {
    margin-right: 0px;
    margin-right: 5px;
  }
  .form__button:last-child {
    margin-left: 5px;
  }
}
@media (max-width: 460px) {
  .price {
    &__input {
      width: 60px;
      font-size: 10px;
    }
    &__table {
      th {
        &:nth-child(2) {
          padding-left: 10px;
        }
      }
      td {
        font-size: 10px;
        width: 15%;
        &:first-child {
          max-width: 40px;
          text-align: left;
        }
        &:nth-child(2) {
          padding-left: 10px;
        }
      }
    }
  }
  .total {
    &__input {
      font-size: 10px;
    }
    &__table {
      tr {
        td {
          &:first-child {
            padding-left: 0px;
          }
        }
      }
      td {
        font-size: 10px;
        text-align: left;
        padding-left: 10px;
        &:first-child {
          text-align: left;
        }
      }
    }
  }
  .select {
    cursor: pointer;
    font-size: 10px;
    &_delivery {
      max-width: 195px;
    }
  }
}
@media (max-width: 440px) {
  .form__row_buttons {
    flex-direction: column;
  }
  .form__button:first-child {
    margin-bottom: 10px;
    margin-right: 0px;
    width: 210px;
  }
  .form__button:last-child {
    margin-left: 0px;
  }
  .specifications__title {
    margin-right: 20px;
  }
}
@media (max-width: 412px) {
  .project__title,
  .form__title {
    font-size: 18px;
  }
  .comments__title {
    padding-left: 0px;
  }
  .form__subtitle {
    padding-left: 0px;
  }
  .form__desc {
    padding-left: 0px;
    font-size: 13px;
  }
  .form__text {
    text-align: center;
  }
  .link {
    color: $main;
    &:hover {
      text-decoration: underline;
    }
  }
  .message {
    left: 0;
  }
}
@media (max-width: 325px) {
  .form__calendar {
    .message {
      top: 135px;
    }
  }
}
</style>
