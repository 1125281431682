/* eslint-disable no-param-reassign */
import { baseDomain } from '../../constans';

const categoryItem = {
  namespaced: true,
  state: { // состояние приложения, данные, которые внутри приложения используются
    categoryItemCompare: {
      products: [],
    },
    categoryItem: {
      subCategory: [],
    },
    infinityPage: 2,
    numberProducts: {
      totalNumberOfFilteredItems: 0,
      totalQuantityOfGoods: 0,
    },
    search: {
      categories: [],
      products: [],
    },
    titleProductItem: '',
    categoryId: 0,
    searchTitle: '',
    sortingState: { sortBy: 'rating', isDesc: true },
    currentPage: 1,
  },
  getters: { // нужны для того чтобы вынимать какие то данные из общих данных приложения, из state, например если будет сложный объект с данными, чтобы не вытягивать каждый раз весь объект, мы можем здесь создать метод, который будет вытаскивать нам только нужные данные
  },
  mutations: { // нужны для того, чтобы описывать методы, которые будут изменять данные нашего приложения
    SET_CATEGORY_ITEM(state, { data, page }) {
      if (page === 1) {
        state.categoryItem.subCategory = data.products;
      } else if (data.products.length !== 0) {
        state.categoryItem.subCategory.push(...data.products);
      }

      state.numberProducts.totalNumberOfFilteredItems = data.totalNumberOfFilteredItems;
      state.numberProducts.totalQuantityOfGoods = data.totalQuantityOfGoods;
    },
    CLEAR_CATEGORY_ITEM(state) {
      state.categoryItem.subCategory = [];
      state.numberProducts.totalNumberOfFilteredItems = 0;
      state.numberProducts.totalQuantityOfGoods = 0;
    },
    UPDATE_MARGINARITY(state, data) {
      for (const key in state.categoryItem.subCategory) {
        if (data.data[state.categoryItem.subCategory[key].id] !== undefined) {
          state.categoryItem.subCategory[key].marginStatus = data.data[state.categoryItem.subCategory[key].id]
            ? 'success' : 'disabled';
        }
      }
    },
    SET_CATEGORY_ITEM_COMPARE(state, data) {
      state.categoryItemCompare.products = data;
    },
    ADD_CATEGORY_ITEM_COMPARE(state, data) {
      state.categoryItemCompare.products.push(data);
    },
    DELETE_MODEL_COMPARE(state, id) {
      const filtered = state.categoryItemCompare.products.filter((product) => product.totalInfo.id !== +id);

      state.categoryItemCompare.products = filtered;

      const product = state.categoryItem.subCategory.find((good) => good.id === +id);

      if (product) product.compare = false;
    },
    DELETE_ALL_MODEL_COMPARE(state) {
      state.categoryItemCompare.products = [];
    },
    SET_SEARCH_RESULT(state, data) {
      state.search.categories = data.categories;
      state.search.products = data.products;
    },
    CLEAR_SEARCH_RESULT(state) {
      state.search.categories = [];
      state.search.products = [];
    },
    SET_TITLE_PRODUCT_ITEM(state, text) {
      state.titleProductItem = text;
    },
    SET_CATEGORY_ID(state, id) {
      state.categoryId = id;
    },
    SET_SEARCH_TITLE(state, text) {
      state.searchTitle = text;
    },
    SET_SORTING_STATE(state, data) {
      state.sortingState = data;
    },
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
  },
  actions: {
    async checkMarginarity({ commit, dispatch }, { priceData, cb }) {
      const token = localStorage.alcotecUserInfo
        ? (JSON.parse(localStorage.alcotecUserInfo).user
          ? JSON.parse(localStorage.alcotecUserInfo).user.token
          : false)
        : false;

      fetch(`${baseDomain}/margin-status`, {
        method: 'POST',
        headers: token ? {
          'auth-token': token,
        } : {},
        body: JSON.stringify({
          mData: priceData,
        }),
      }).then((resp) => resp.json()).then((respData) => {
        if (respData.renew_jwt) {
          dispatch('profiles/refreshToken', {
            action: 'categoryItem/checkMarginarity',
            priceData,
            cb,
          }, { root: true });
        }
        commit('UPDATE_MARGINARITY', { data: respData });
        if (cb !== undefined) {
          cb(respData);
        }
      });
    },
    async getCategoryItem({ commit, dispatch }, {
      cb, categoryId, page = 1, filter = {}, PER_PAGE = 15, sorting = commit.sortingState, error,
    }) {
      const token = localStorage.alcotecUserInfo
        ? (JSON.parse(localStorage.alcotecUserInfo).user
          ? JSON.parse(localStorage.alcotecUserInfo).user.token
          : false)
        : false;

      fetch(`${baseDomain}/products/${categoryId}`, {
        method: 'POST',
        headers: token ? {
          'auth-token': token,
        } : {},
        body: JSON.stringify({
          sorting,
          page,
          perpage: PER_PAGE,
          filter,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', {
              action: 'categoryItem/getCategoryItem',
              cb,
              categoryId,
              page,
              filter,
              PER_PAGE,
              sorting,
              error,
            }, { root: true });
          } else if (!response.products) {
            error();
          } else {
            const subCategory = response.products.map((e) => {
              e.addedToBasket = 0;

              return e;
            });

            if (token) {
              const priceData = [];

              response.products.forEach((i) => {
                if (i.marginStatus === 'pending') {
                  priceData.push({
                    id: i.id,
                    priceUAH: i.priceUAH,
                    sale: i.sale,
                  });
                }
              });
              dispatch('checkMarginarity', { priceData: priceData });
            }
            response.subCategory = subCategory;

            cb(response);
            commit('SET_CATEGORY_ITEM', { data: response, page });
          }
        })
        .catch((err) => console.error(err));
    },
    async getSearchItem({ commit }, {
      cb, search, page = 1, filter = {}, PER_PAGE = 12, sorting = commit.sortingState, error,
    }) {
      fetch(`${baseDomain}/search-page/${search}`, {
        method: 'POST',
        body: JSON.stringify({
          sorting,
          page,
          perpage: PER_PAGE,
          filter,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.success) {
            error();
          } else {
            if (response.status === 'redirectItem') {
              cb('redirect', `/catalog/category/${response.catId}/product/${response.itemId}`);
            } else if (response.status === 'redirectCat') {
              cb('redirect', `/catalog/category/${response.catId}`);
            } else if (response.status === 'products') {
              const subCategory = response.products.map((e) => {
                e.addedToBasket = 0;

                return e;
              });

              response.subCategory = subCategory;

              cb('products', response);
              // commit('SET_SEARCH_ITEM', { data: response, page: page });
              commit('SET_CATEGORY_ITEM', { data: response, page });
              commit('SET_SEARCH_TITLE', search);
            } else {
              error();
            }
          }
        })
        .catch((err) => console.error(err));
    },
    async getProduct({ commit, dispatch }, { id, cb }) {
      const token = localStorage.alcotecUserInfo
        ? (JSON.parse(localStorage.alcotecUserInfo).user
          ? JSON.parse(localStorage.alcotecUserInfo).user.token
          : false)
        : false;

      fetch(`${baseDomain}/product/${id}`, {
        headers: token ? {
          'auth-token': token,
        } : {},
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', {
              action: 'categoryItem/getProduct',
              id,
              cb,
            }, { root: true });
          } else if (response.totalInfo) {
            response.totalInfo.compare = false;
            commit('ADD_CATEGORY_ITEM_COMPARE', response);
            cb(response);
          } else {
            throw response;
          }
        })
        .catch((err) => console.error(err));
    },
    async getSearchProductsAndCategories({ commit }, { searchValue, cb }) {
      fetch(`${baseDomain}/search?searchValue=${searchValue}`)
        .then((response) => response.json())
        .then((response) => {
          if (response.categories) {
            commit('SET_SEARCH_RESULT', response);
          } else {
            throw response;
          }
        })
        .catch((err) => console.error(err));
    },
  },
};

export default categoryItem;
